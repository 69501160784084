import React from "react";
import InfoBoxTitle from "./InfoBoxTitle";

const SecondaryInfoBox = ({ textContent, selectLanguage }) => {
  return (
    <div className="w-full p-2 mb-6 bg-itemBackground rounded-[20px] border border-[#4d2c6c]">
      <InfoBoxTitle
        titleText={textContent["secondaryInfoBoxTitle"][selectLanguage]}
      />
      <p className="w-full text-center items-center text-primaryText text-[22px] font-bold my-3">
        {textContent["priceFromUkraineText"][selectLanguage]}
      </p>
      <ul
        className="w-full px-6 text-left text-bold flex flex-col justify-center
              text-primaryText text-[20px] gap-y-3"
      >
        {textContent["secondaryInfoBoxDescription"][selectLanguage].map(
          (list, index) => (
            <li key={index}>{list}</li>
          )
        )}
      </ul>
    </div>
  );
};

export default SecondaryInfoBox;
