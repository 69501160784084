import React from "react";

const TextBox = ({ title, description }) => {
  return (
    <div className="w-full p-4 mb-6 bg-itemBackground rounded-[20px] border border-[#4d2c6c] ">
      <div className="w-full flex flex-col px-6">
        <p className="text-[20px] text-[#ffffff] font-re">{title}</p>
        <div className="w-5/6 h-[4px] mt-2 bg-secondaryText"></div>
        <p className="text-[14px] mt-4 ml-4 text-[#A9A7B0] font-normal">
          {description}
        </p>
      </div>
    </div>
  );
};

export default TextBox;
