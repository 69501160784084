import React from "react";
import MainPage from "./app/pages/MainPage";

function App() {
  return (
    <div className="container flex items-center justify-center max-w-[1600px] ">
      <MainPage />
    </div>
  );
}

export default App;
