import React from "react";
import DownloadBtn from "../components/mobile/DownloadBtn";
import logo from "./../../assets/ico/anna-logo.png";
import InfoBoxContainer from "../components/mobile/InfoBoxContainer";
import TextBoxContainer from "../components/mobile/TextBoxContainer";
import SecondaryInfoBox from "../components/mobile/SecondaryInfoBox";
import AccordionTextBox from "../components/mobile/AccordionTextBox";
import data from "../data/data";
import SocialLinks from "../components/mobile/SocialLinks";
import ContactLinks from "../components/mobile/ContactLinks";

const MobileScreen = ({ textContent, selectLanguage, onToggleLanguage }) => {
  return (
    <div className=" max-w-[850px]">
      <div
        className=" bg-no-repeat bg-center bg-cover bg-[url('assets/img/IMG_bg6.png')]
        w-full flex flex-col item-center justify-between h-[90vh] min-h-[670px]"
      >
        <div>
          <div className="w-full flex justify-end px-3 items-center">
            <button
              onClick={() => onToggleLanguage("ua")}
              className="text-primaryText fontSize_24 font-thin leading-[28px] p-2"
            >
              UA
            </button>
            <div className="border-r-[1px] border-primaryText h-[20px] "></div>
            <button
              onClick={() => onToggleLanguage("en")}
              className="text-primaryText fontSize_24 font-thin leading-[28px] p-2"
            >
              EN
            </button>
          </div>
          <div className="w-full flex justify-center item-center mt-[10vh]">
            <div className="w-[150px]">
              <img src={logo} />
            </div>
          </div>
          <h1 className="text-center mt-8 text-4xl text-[#ffffff] font-bold">
            ANNA
          </h1>
          <div className="w-full flex justify-center item-center mt-10">
            <p className="text-center text-2xl text-[#ffffff] font-medium w-5/6">
              {textContent["description"][selectLanguage]}
            </p>
          </div>
        </div>
        <div
          className=" w-full bg-gradient-to-t from-[#1A1827] h-[30px]
            "
        ></div>
      </div>

      {/* ---------- */}
      <div className="-mt-[15vh]">
        <p className="text-center text-lg text-[#ffffff] font-semibold">
          {textContent["download"][selectLanguage]}
        </p>
        <div className="sticky top-0 block  pt-1 z-50">
          <DownloadBtn />
        </div>

        <div className="mt-[7vh]">
          <InfoBoxContainer
            textContent={textContent}
            selectLanguage={selectLanguage}
          />
        </div>
        <div>
          <TextBoxContainer
            textContent={textContent}
            selectLanguage={selectLanguage}
          />
        </div>
        <div className="mt-6 px-2 w-full">
          <SecondaryInfoBox
            textContent={textContent}
            selectLanguage={selectLanguage}
          />
        </div>
        <div className="mt-6 px-2 w-full">
          <AccordionTextBox
            data={textContent["userQuestions"]}
            selectLanguage={selectLanguage}
          />
        </div>
        <div
          className=" w-full bg-gradient-to-t from-[#181528] h-[10px]
            "
        ></div>
      </div>

      {/* ------------------------ */}
      <div
        className=" bg-no-repeat bg-center bg-cover bg-[url('assets/img/IMG_bg6.png')]
        w-full flex flex-col item-center justify-between h-[101vh] min-h-[670px]"
      >
        <div className="w-full flex justify-center item-center mt-[20vh]">
          <div className="w-[150px]">
            <img src={logo} />
          </div>
        </div>
        <div>
          <h2 className="text-center mt-[6vh] text-[22px] text-[#ffffff] font-bold">
            {data["downloadNow"][selectLanguage]}
          </h2>
          <div className="mt-4">
            <DownloadBtn />
          </div>
          <div>
            <SocialLinks />
          </div>
        </div>
        <div>
          <ContactLinks />
        </div>
      </div>
    </div>
  );
};

export default MobileScreen;
