import React from "react";
import tellIcon from "./../../../assets/ico/tell-icon.svg";
import mailIcon from "./../../../assets/ico/mail-icon.svg";

const ContactLinks = () => {
  return (
    <div className="flex w-full flex-row justify-between gap-[10%] mt-[10%] p-5">
      <a href="tel:+380956255314">
        <div className="w-[60px] h-[60px] rounded-full bg-[rgb(168,151,255,0.1)] flex justify-center items-center buttonSocial">
          <img src={tellIcon} alt="inst" />
        </div>
      </a>

      <a href="mailto:info@getanna.app">
        <div className="w-[60px] h-[60px] rounded-full bg-[rgb(168,151,255,0.1)] flex justify-center items-center buttonSocial">
          <div className="w-[60px] h-[60px] rounded-full bg-[rgb(168,151,255,0.1)] flex justify-center items-center buttonSocial">
            <img src={mailIcon} alt="inst" />
          </div>
        </div>
      </a>
    </div>
  );
};

export default ContactLinks;
