import React from "react";
import instagramIcon from "./../../../assets/ico/instagram-icon.svg";
import facebookIcon from "./../../../assets/ico/facebook-icon.svg";
import tiktokIcon from "./../../../assets/ico/tiktok-icon.svg";

const SocialLinks = () => {
  return (
    <div className="flex w-full flex-row justify-center gap-[10%] mt-[10%]">
      <a href="https://www.instagram.com/anna_platform/">
        <div className="w-[60px] h-[60px] rounded-full bg-[rgb(168,151,255,0.1)] flex justify-center items-center buttonSocial">
          <img src={instagramIcon} alt="inst" />
        </div>
      </a>
      <a href="https://www.tiktok.com/@anna_platform">
        <div className="w-[60px] h-[60px] rounded-full bg-[rgb(168,151,255,0.1)] flex justify-center items-center buttonSocial">
          <img src={tiktokIcon} alt="titok" />
        </div>
      </a>
      <a href="https://www.facebook.com/assistantiAnna/">
        <div className="w-[60px] h-[60px] rounded-full bg-[rgb(168,151,255,0.1)] flex justify-center items-center buttonSocial">
          <img src={facebookIcon} alt="inst" />
        </div>
      </a>
    </div>
  );
};

export default SocialLinks;
