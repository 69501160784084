import React from "react";

const InfoBoxTitle = ({ titleText }) => {
  return (
    <div className="w-full">
      <p className="text-[32px] px-3 text-[#ffffff] font-bold">{titleText}</p>
      <div className=" w-full px-4 my-2">
        <div className="w-full h-[8px] bg-secondaryText skew-x-[100deg]"></div>
      </div>
    </div>
  );
};

export default InfoBoxTitle;
