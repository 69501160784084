const data = {
  description: {
    ua: "Мобільний додаток для онлайн бронювання візитів",
    en: "Mobile app for online booking of visits",
  },
  download: {
    ua: "Завантажити",
    en: "Download",
  },
  onlineBookingTitle: {
    ua: "Онлайн бронювання",
    en: "Online booking",
  },
  onlineBookingDescription: {
    ua: "Бронювання візитів через додаток. Ваш бізнес завжди онлайн, 24/7",
    en: "Booking of visits via the app. Your business is always online, 24/7",
  },
  digitalNotebookTitle: {
    ua: "Електронний блокнот",
    en: "Digital notebook",
  },
  digitalNotebookDescription: {
    ua: "Створюйте записи в мобільному додатку без дзвінків та повідомлень. Контролюйте свій графік та план роботи прямо в додатку",
    en: "Make notes in the mobile app without calls and messages. Control your schedule and work plan right in the app.",
  },
  webBookingTitle: {
    ua: "WEB бронювання",
    en: "WEB booking",
  },
  webBookingDescription: {
    ua: "Посилання на ваш профіль для онлайн запису ваших клієнтів без додатку. Тепер все, що потрібно - це відправити посилання клієнту та додати лінк у свій Інстаграм.",
    en: "Link to your profile for online registration of your customers without an app. Now all you need is to send the link to the client and add the link to your Instagram.",
  },
  crmTitle: {
    ua: "CRM",
    en: "CRM",
  },
  crmDescription: {
    ua: "Всі клієнти в одній системі, всі записи в одному додатку. Асистент для твого бізнесу. Просто займайся бізнесом.",
    en: "All clients in one system, all records in one application. Your business assistant - enabling you to focus solely on your work.",
  },
  remindersTitle: {
    ua: "Нагадування",
    en: "Reminders",
  },
  remindersDescription: {
    ua: "Ваші клієнти автоматично отримують сповіщення про запис та нагадування про візити.",
    en: "Your clients automatically receive appointment notifications and visit reminders.",
  },
  controlTitle: {
    ua: "Контроль",
    en: "Control",
  },
  controlDescription: {
    ua: "Ваші доходи, графік роботи, календар запису та багато корисного - завжди під руками. Ваш мобільний телефон - це ваш бізнес-асистент.",
    en: "Your earnings, work schedule, appointment calendar, and various useful tools are always within reach. Your mobile phone serves as your business assistant.",
  },
  secondaryInfoBoxTitle: {
    ua: "Скільки це чудо коштує?",
    en: "What is the cost of a monthly subscription?",
  },
  secondaryInfoBoxDescription: {
    ua: [
      "- Доступна безкоштовна версія підписки.",
      "- Не більше 1 працівника - 7$/міс.",
      "- Необмежено по функціоналу та працівниках - 12$/міс.",
    ],
    en: [
      "- A free subscription version is available.",
      "- No more than 1 employee - $7/month.",
      "- Unlimited functionality and employees - $12/month.",
    ],
  },
  priceFromUkraineText: {
    ua: ["Тільки для України!!! Мега пропозиція:"],
    en: ["Only for Ukraine!!! Mega offer:"],
  },
  userQuestions: [
    {
      ua: {
        question:
          "Під час користування Anna у мене зʼявилося занадто багато клієнтів, що тепер?",
        answer: "Не турбуйся, Anna все тримає під контролем.",
      },
      en: {
        question:
          "With Anna, my customer base has expanded significantly,  now what?",
        answer: "Don't worry, Anna has everything under control.",
      },
    },
    {
      ua: {
        question: "Про мій бізнес тільки хороші відгуки. Що мені робити?",
        answer: "Вітаємо, ти - в топі.",
      },
      en: {
        question:
          "All I see are positive reviews for my business. What's my next move?",
        answer: "Congratulations, you're on top.",
      },
    },
    {
      ua: {
        question: "Що тепер робити з блокнотом? Він мені більше не потрібен?",
        answer:
          "Не хвилюйся, можеш робити у ньому записи кулінарних рецептів 😉",
      },
      en: {
        question: "What should I do with the notebook now? Do I still need it?",
        answer: "No worries, you can use it to jot down culinary recipes 😉",
      },
    },
    {
      ua: {
        question:
          "Я так розумію, реклама мені більше не потрібна. Як тепер з цим жити?",
        answer:
          "Так, дійсно не потрібна. Твій бізнес - на маркетплейсі. Все! Можеш задонатити на ЗСУ.",
      },
      en: {
        question:
          "I realize I no longer require advertising. How do I navigate this change?",
        answer:
          "Exactly, no more need for ads. Your business is now on the marketplace. That's it! Now, consider donating to the Armed Forces of Ukraine.",
      },
    },
  ],
  downloadNow: {
    ua: "Завантажити прямо зараз",
    en: "Download right now",
  },
};

export default data;
