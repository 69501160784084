import React from "react";
import InfoBoxDescription from "./InfoBoxDescription";
import InfoBoxTitle from "./InfoBoxTitle";

const InfoBox = ({ titleText, imgSrc, descriptionText }) => {
  return (
    <div className="w-full p-2 mb-6 bg-itemBackground rounded-[20px] border border-[#4d2c6c]">
      <InfoBoxTitle titleText={titleText} />
      <InfoBoxDescription imgSrc={imgSrc} descriptionText={descriptionText} />
    </div>
  );
};

export default InfoBox;
