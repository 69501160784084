import React from "react";

const Terms = () => {
  return (
    <div className="p-3 w-full flex items-center justify-center">
      <div className="flex flex-col bg-primaryText p-1">
        <p className="termsTitle">Terms of Use</p>
        <p className="termsText">
          Services (as defined herein) and Terms of Use are provided by INCOMM-
          SPACE Corp. - the PRODUCT/PROJECT/MOBILE APPLICATION "ANNA", "ANNA
          ASSISTANT" hereinafter referred to as (ANNA).
        </p>
        <p className="mt-2 termsTextBold">
          USERS WHO DO NOT AGREE TO THESE TERMS OF USE SHOULD NOT USE ANNA'S
          SERVICES AND PRODUCT! THIS AGREEMENT INCLUDES, AMONG OTHER THINGS,
          ARBITRATION PROVISIONS THAT CONTAIN A WAIVER OF CLASS ACTION.
        </p>

        <p className="termsSubtitle">
          Part I – Definitions, mandatory agreement.
        </p>
        <p className="termsSubtitle">1.1 Definitions.</p>
        <p className="termsText">
          References to "Reservation Services" mean the meeting planning
          services provided through the ANNA application. References to "ANNA
          Application" mean any mobile, web, or voice application associated
          with the Services, developed and/or provided by us and available
          through the APP STORE, iTunes, and Google Play, as well as other
          third-party services, including but not limited to Amazon Alexa and
          Google Home. References to "ANNA Site" mean the websites with the
          URLs:
        </p>
        <a className="underline leading-5 my-1" href="http://getanna.app">
          http://getanna.app
        </a>
        <a className="underline leading-5  my-1" href="http://i-ANNA.com">
          http://i-ANNA.com
        </a>
        <a className="underline leading-5  my-1" href="http://annaweb.space">
          http://annaweb.space
        </a>
        <p className="termsText">
          and all subsidiary websites owned and operated by INCOMM-SPACE Corp.
          USA, our subsidiaries (INCOMM.SPACE LLC, Ukraine), and all other
          affiliated companies. References to "Commercial Content" mean content
          that advertises or promotes a commercial product or service.
          References to "Client" mean any individual or legal entity using the
          ANNA Application and ANNA Site or a Third-Party platform, as defined
          in Section 3.8, to schedule meetings, manage meetings, view content
          related to health, beauty, and well-being, and other services, whether
          paying or not paying for the services, whether the person registered
          directly for the use of the ANNA program or added by another person
          through whom the Client receives services. References to "Dispute"
          mean any claim, conflict, dispute, or differences between the Parties
          arising out of or in any way related to these Terms (or any terms,
          supplements, or amendments provided herein), including, without
          limitation, any action in tort, contract, or other law, or any alleged
          breach, including but not limited to any question concerning the
          meaning, consequences, validity, performance, termination,
          interpretation, or enforcement of these Terms or any terms provided
          herein. References to "Material Breach" mean any breach of these
          Terms, upon the occurrence of which a reasonable person in the
          position of the non-breaching party wishes to immediately terminate
          the operation of these Terms due to such breach. References to
          "Services" mean all services we offer, including, but not limited to,
          (1) the website, (2) the application, (3) payment services, (4)
          “portf.olio”, and (5) any other services or features provided through
          the services. References to "SME" mean small or medium-sized
          businesses, self-employed individuals, individuals, and sellers of
          goods, services, or products using the Services to enable Clients to
          book, manage, view, and cancel meetings. References to "Terms," "Terms
          of Use," and/or "Agreement" mean these terms as set forth here.
          References to "us," "we," "our," and/or "ANNA" refer to Incomm-space
          Corp., as well as all its designated agents, employees, and
          subsidiaries within the United States for clients located and/or
          residing in North and South America, Australia, and other (all)
          countries worldwide. For clients located and/or residing in Ukraine,
          the terms "ANNA" and "we," "us," and/or "our" refer to INCOMM.SPACE
          LLC, Ukraine, Zakarpattia region, Uzhhorod city, as well as all its
          designated agents, employees, and subsidiaries in the European Union
          and CIS countries, Ukraine. To avoid doubt, the person to whom you
          provide indirect consent by using the Services depends on your place
          of residence and the place of use of such Services. References to
          "you" and/or "User" mean the user of the Services, whether an SME
          self-employed person or a Client.
        </p>
        <p className="termsSubtitle">1.2. Binding Agreement.</p>
        <p className="termsText mb-3">
          The following Terms of Use, together with relevant information
          provided in the Services, including any available features and
          services, are governed by the Terms of Use set forth below. Please
          read them carefully, as any use of the Services, directly through us
          or through a Third-Party platform, constitutes an acceptance agreement
          that the User is obligated to comply with. By accessing or using the
          Services or clicking "accept" or "agree" to this Agreement, you
          represent (1) that you are at least eighteen (18) years old (and in
          some jurisdictions, twenty-one (21) years old), (2) it is not illegal
          for you to access or use the Services, (3) you have read, understood,
          and agree to abide by this Agreement, and (4) you have the authority
          to register an SME and act on its behalf. Parties acknowledge and
          agree that each of our companies, SME, client, third- party platform,
          subsidiary, parent, and affiliated companies are third-party
          beneficiaries under these Terms of Use. Such other individuals and/or
          companies have the right to directly enforce and rely upon any
          provision of these Terms of Use that benefits them. No other party
          shall be a third-party beneficiary under these Terms of Use.
        </p>
        <p className="termsSubtitle">Part II – General Provisions.</p>
        <p className="termsSubtitle">2.1. About us.</p>
        <p className="termsText">
          In essence, ANNA operates as software as a service, allowing users to
          book, manage, view, and cancel meetings. Additionally, the ANNA
          platform and all related programs enable users to view, browse, and
          explore content and potential service providers related to health,
          wellness, beauty, and other services. ANNA does not control (1) the
          quality, safety, morality, or legality of any aspects of any services
          offered by SMEs using our services, (2) the truth or accuracy of
          listings or catalog information provided by SMEs to us, (3) the
          timeliness or accuracy of any SME's appointment calendar, (4) the
          ability of SMEs to provide the ordered services, (5) the identity of
          any SME or client, or (6) the ability of clients to attend and pay for
          services. We do not guarantee and cannot guarantee that SMEs or
          clients will actually complete a transaction or show up for a visit.
          If you rely on any information provided by the services, you do so
          solely at your own risk. You acknowledge and agree that we do not sell
          or purchase, offer to sell or buy, invite to sell or purchase, make or
          solicit any offers.
          <span className="termsTextBold">
            <br />
            IN ALL CASES, UNLESS SPECIFIED, ALL SALES AND SERVICES ARE
            ADVERTISED, SOLICITED, OFFERED, ACCEPTED, MADE, AND PROVIDED BY
            SMEs. IN NO EVENT WILL THERE BE ANY CLIENT-SERVICE PROVIDER
            RELATIONSHIP BETWEEN US, EXCEPT AS SPECIFIED IN THIS AGREEMENT.
            <br />
          </span>
          In all cases, any requests, invitations, offers, advertisements, or
          messages are void if prohibited by law.
        </p>
        <p className="termsSubtitle">
          2.2. Accuracy, Completeness, and Timeliness of Information.
        </p>
        <p className="termsText">
          We are not responsible if the information available in the Services is
          not accurate, complete, or up-to-date, including, but not limited to,
          SME corporate information, calendar availability, and made bookings.
        </p>
        <p className="termsSubtitle">2.3. Errors in the Services.</p>
        <p className="termsText">
          If you encounter any errors in the service, please inform us at
          office@incomm.space . We do not guarantee that any errors in the
          Services will be corrected.
        </p>
        <p className="termsSubtitle">
          2.4. Modifications and Changes to the Terms of Use.
        </p>
        <p className="termsText">
          We, the Company, may update or revise this Agreement (including any
          Policy) at our discretion and without limitation by posting it on the
          mobile application and/or company websites. You agree to periodically
          review this Agreement. You may choose to accept or not accept the
          modified version of this Agreement, but accepting this Agreement in
          its modified form is necessary for you to continue using the Services.
          You may need to click "accept" or "agree" to demonstrate your consent
          to any modified version of this Agreement. If you do not agree to the
          terms of this Agreement or any modified version of this Agreement,
          your only recourse is to cease using the Services, and in that case,
          you will no longer have access to your Account or SME Account (as
          defined below). Unless otherwise expressly stated by ANNA, any use of
          the Services (e.g., use of booking services or payment services) is
          governed by the version of this Agreement in effect at the time of
          use.
        </p>
        <p className="termsSubtitle">
          2.5. Modifications and Changes to the Services.
        </p>
        <p className="termsText">
          We may change, supplement, suspend, or delete any aspect of the
          Services, in whole or in part, at our discretion at any time, and such
          changes, supplements, or deletions take effect immediately. Such
          changes, supplements, or deletions may include, but are not limited
          to, proposed content, availability hours, and necessary equipment for
          access or use. If you do not agree with such changes in the Services,
          your only recourse is to cease using the Services, and in that case,
          you will no longer have access to your Account or SME Account (as
          defined below). Unless otherwise expressly stated by ANNA, any use of
          the Services (e.g., use of booking services or payment services) is
          governed by the version of this Agreement in effect at the time of
          use.
        </p>
        <p className="termsSubtitle">2.6. Access to the Website or Services.</p>
        <p className="termsText">
          While we strive to make the Services available twenty-four (24) hours
          a day, seven (7) days a week, except for planned downtime for
          maintenance, we do not guarantee that the Services will be available
          at all times. To use the Services, access to the Internet through a
          computer, mobile device, tablet, or other device authorized by ANNA is
          required. You are responsible for all mobile carrier charges
          associated with your use of the Services, including any notifications
          provided by the Services. ANNA does not guarantee that the Services
          will be compatible with all devices or supported by all mobile
          carriers.
        </p>
        <p className="termsSubtitle">
          2.7. Right to Refuse, Restrict, Suspend, and Terminate.
        </p>
        <p className="termsText">
          We reserve the right to refuse access to the Services for any reason
          at any time at our discretion. We may, at our sole discretion,
          restrict or terminate the account or SME account (as defined below)
          for any reason. In the event of changes or termination of the account
          or SME account (as defined below), we may attempt to notify you by
          contacting the email and/or payment address/phone number provided
          during your account or SME account (as defined below) creation;
          however, failure to do so will not result in any liability, including
          liability for data loss deleted as a result of account or SME account
          (as defined below) termination.
        </p>
        <p className="termsSubtitle">2.8. Prohibited Use of Services.</p>
        <p className="termsText">
          In addition to other usage restrictions outlined in this document, you
          agree and acknowledge that you will not use the Services to: (a) for
          any unlawful purpose; (b) induce users to perform any illegal actions
          or participate in them or engage in activities unrelated to the
          purposes of the Services; (c) violate any international or government
          provisions, rules, laws, or local ordinances; (d) infringe upon or
          violate our intellectual property rights or the intellectual property
          rights of others; (e) for harassment, abuse, insult, harm, defame,
          slander, libel, intimidate, or discriminate based on gender, sexual
          orientation, religion, ethnicity, race, age, national origin, or
          disability; (f) provide false or misleading information; (g) upload or
          transmit viruses or any other type of malicious code that will or may
          be used in any way that will affect the functionality or operation of
          the Services; (h) for the collection or tracking of personal
          information of other individuals; (i) spam, phishing, pharm, pretext,
          spider, crawl, or scrape; (j) for any indecent or immoral purpose; (k)
          to interfere or circumvent the security features of the Services; (l)
          engage in any actions that cause or may cause (at the sole discretion
          of ANNA) an unreasonable or disproportionately large load on the
          Services or ANNA's infrastructure; (m) lease, rent, copy, provide
          access to, or sublicense any portion of the Services or ANNA Materials
          (as defined below) to a third party; (n) use any part of the Services
          or ANNA Materials (as defined below) to provide or incorporate any
          part of the Services or ANNA Materials (as defined below) into any
          product or service offered by a third party; (o) reverse engineer,
          decompile, disassemble, or otherwise attempt to obtain the source code
          or closed API for the Services, except as expressly permitted by
          applicable law (and only after prior notice to ANNA); (p) modify any
          Services or ANNA Materials (as defined below) or create any derivative
          products based on any of the foregoing; (q) remove or obscure any
          proprietary or other notices contained in the Services or ANNA
          Materials (as defined below); or (r) publicly disseminate information
          about the performance of the Services or ANNA Materials (as defined
          below) or access or use the Services or ANNA Materials (as defined
          below) for competitive analysis or comparative analysis. We reserve
          the right to terminate your use of the Services for violation of any
          of the prohibited uses or for any other reason at our sole discretion.
        </p>
        <p className="termsSubtitle">
          2.9. Communication Systems and Authorization for Communication with
          You; Open Information; Call Recording; Analysis of Your Messages and
          Other Content.
        </p>
        <p className="termsText">
          The ANNA program may use GPS locator features to determine your
          current location. If you choose to provide a mobile phone number and
          mark the corresponding consent field during customer account login,
          you acknowledge that you will receive text SMS messages, phone calls,
          emails, and agree to receive them, push notifications, in-app
          communications, and web browser technology containing commercial
          content, such as web beacons and other chatbot applications from ANNA
          regarding the Services and more, as described in our Privacy Policy.
          You may incur charges for such messages according to your agreement
          with the operator. Additionally, by providing your contact information
          and/or linking associated accounts, you grant ANNA access and consent
          to communicate with you using voice assistants like Siri, Alexa, and
          Google Home, or any other similar voice services.
          <br />
          ANNA may contact you through automatic dialing or pre-recorded calls
          and text messages using any provided phone number to: (i) inform you
          about your account; (ii) resolve issues with your account; (iii)
          settle disputes; (iv) occasionally solicit your opinion through
          surveys or questionnaires; or (v) if necessary for the maintenance of
          your account or to ensure compliance with this Agreement, our policy,
          applicable laws, or any other agreement we may enter into with you.
          <br />
          ANNA may also contact you through automatic or pre-recorded calls and
          text messages for marketing purposes (e.g., offers and promotional
          campaigns) to which you agree by providing your mobile phone number
          (as specified above). ANNA may collect other phone numbers for you and
          may make manual non- marketing calls to any of these numbers and
          automatic non-marketing calls to any landline. Standard minute and
          text message charges may apply, and it may include overage charges if
          you exceed the plan limit. If you have questions about text message or
          data plans, please contact your wireless carrier. <br />
          If you do not wish to receive messages as specified above, you can
          change your communication settings (including withdrawing your consent
          to receive specified messages) at any time, including through the
          communication settings section in your account. ANNA may, without
          additional notice or warning and at its discretion, monitor or record
          phone conversations that you or anyone acting on your behalf has with
          ANNA or its agents for quality control and training purposes or for
          its own protection. <br />
          Any personal information provided by you to ANNA or collected by ANNA
          as mentioned above will be stored and processed by ANNA in accordance
          with our Privacy Policy.
        </p>
        <p className="termsSubtitle">2.10. Confidentiality; Marketing.</p>
        <p className="termsText">
          If ANNA provides you with information about another User, you agree to
          use this information only for the purposes provided to you. You do not
          have the right to disclose, sell, rent, or distribute User information
          to third parties for purposes unrelated to the Services. Additionally,
          you do not have the right to use user information for marketing
          purposes through electronic or other means unless you obtain the
          specific user's consent.
        </p>
        <p className="termsSubtitle">2.11. Government End Users.</p>
        <p className="termsText">
          The Services are a “commercial product” as defined in 48 CFR 2.101,
          Code of Federal Regulations for the Use of “Commercial Computer
          Software” and “Commercial Computer Software Documentation,” as such
          terms are used in 48 CFR 12.212. Pursuant to 48 CFR 12.212 and 48 CFR
          227.7202-1 through 227.7202-4, all US Government end users obtain any
          ANNA Program only with the rights set forth therein. If you are a
          government end user, please consult with the appropriate legal
          department before use to confirm that your use complies with the
          standards and policies under the Code of Federal Regulations.
        </p>
        <p className="termsSubtitle">2.12. Export Control.</p>
        <p className="termsText">
          You are not authorized to use, export, or re-export any ANNA program
          or other aspects of the Services (or any copy or adaptation thereof)
          in violation of applicable laws, including, without limitation, U.S.
          export laws and regulations. You represent and warrant that you are
          not located in a country subject to a U.S. Government embargo or that
          has been designated by the U.S. Government as a country that "supports
          terrorists," and that you are not listed on any U.S. Government list
          of prohibited or restricted parties.
        </p>
        <p className="termsSubtitle">2.13. User Fraudulent Actions.</p>
        <p className="termsText mb-3">
          ANNA is not responsible for any losses related to the revocation of
          payments, fraudulent recoveries, or any other actions of any User that
          are fraudulent, deceptive, or otherwise invalid ("User Fraudulent
          Actions"). By using the Services, you hereby release ANNA from any
          liability arising from fraudulent actions. You will also make all
          efforts to promptly inform us of any fraudulent actions that may
          affect the Services. ANNA reserves the right at its sole discretion to
          suspend the account of any User engaging in fraudulent actions or
          allowing any other User to engage in such actions.
        </p>
        <p className="termsSubtitle">Part III – Customer Accounts.</p>
        <p className="termsSubtitle">3.1 Online Accounts.</p>
        <p className="termsText">
          Customers will have the opportunity to register using an online,
          mobile, voice, or offline registration form to create a Customer
          account (hereinafter "Account"), enabling you to receive information
          from us and/or participate in certain features of the Services. We
          will use the information provided by you in accordance with our
          Privacy Policy. By registering with us, you declare and warrant that
          all information you provide in the registration form is current,
          complete, and accurate to the best of your knowledge. You agree to
          maintain and promptly update your registration information to keep it
          current, complete, and accurate. During the registration process, you
          may need to choose a password. You acknowledge and agree that we may
          rely on this password for your identification. You are responsible for
          any use of your Account, whether or not you authorized such access or
          use, and for ensuring that any use of your Account complies with these
          Terms of Service. Accounts can be canceled according to the
          functionality of the ANNA mobile application.
        </p>
        <p className="termsSubtitle">3.2. Transfer Prohibited.</p>
        <p className="termsText">
          You agree not to sell, exchange, or transfer your Account to any other
          person or organization.
        </p>
        <p className="termsSubtitle">3.3. Account Rules.</p>
        <p className="termsText">
          The Services may include the ability to communicate with other Users
          in one or more profiles, booking forms, platforms, comment sections,
          discussion forums, websites, landing pages, social networks, sales
          channels, and/or other interactive features (hereinafter "Interactive
          Zones") where users and third parties may communicate. By
          participating in interactive zones, you agree and acknowledge that
          you: - Shall not upload, distribute, or otherwise publish in the
          Services any defamatory, libelous, indecent, pornographic, offensive,
          or otherwise unlawful materials; and <br />
          - Shall not threaten or verbally abuse other Users, use defamatory
          language, or intentionally disrupt discussions with repetitive
          messages, irrelevant messages, or "spam"; and <br />
          - Shall not use language that is offensive or discriminatory based on
          race, religion, nationality, gender, sexual preference, age, region,
          disability, etc. Any hostile language of any kind is grounds for
          immediate and permanent suspension of Services; and <br />
          - Shall not personally attack another User. Personal attacks are a
          direct violation of these Terms of Service and are grounds for
          immediate and permanent suspension of Services; and <br />
          - Shall not use Interactive Zones to distribute or otherwise publish
          materials containing any solicitations, advertising, or offers of
          goods or services, except for the sale of goods or services within the
          clearly defined purpose of the Services; and <br />
          - Shall not upload, post, or otherwise transmit any content that
          violates any law or engages in activities that may constitute a
          criminal offense or give rise to civil liability; and <br />
          - Shall not post unauthorized commercial messages (e.g., spam); and -
          Shall not upload, post, or otherwise transmit content that advocates
          or provides instructions for illegal activities or discusses illegal
          activities with the intent to commit them; and <br />
          - Shall not upload, post, or otherwise transmit content that is
          generally unrelated to the designated topic or theme of any
          Interactive Zone; and - Shall not impersonate any person or entity,
          including, but not limited to, any of our employees, or falsely state
          or otherwise misrepresent your affiliation with any person or entity;
          and <br />
          - Shall not interfere with the privacy rights of any other User,
          including by collecting or gathering personal information about other
          Users from our Interactive Zones or posting private information about
          a third party; and - Shall not engage in illegal multi-level
          marketing, such as a pyramid scheme; and <br />
          - Shall not upload, post, or otherwise transmit any content, software,
          or other materials that contain a virus or other harmful or disruptive
          component; and - Shall not interfere with the operation of the
          Services or Interactive Zones or the servers or networks connected to
          them, or violate any requirements, procedures, policies, or
          regulations of networks connected to the Services and/or Interactive
          Zones; and <br />
          - Shall not encourage or endorse any violations of these Terms of
          Service or any other ANNA policy; and <br />
          - Shall not upload, post, or otherwise publish any information (in the
          form of links or otherwise) about organizations that provide
          competitive services to ANNA. <br />
          Users agree and acknowledge that any profile they create may be
          edited, deleted, modified, published, transmitted, or displayed by us,
          and they waive any rights they may have to alter or change the
          material in a manner they do not like.
        </p>
        <p className="termsSubtitle">3.4. Submission Rights.</p>
        <p className="termsText">
          If you submit, display, publish, or otherwise post any content in an
          interactive zone (hereinafter "Submission"), you agree to grant us,
          our partners, and affiliated entities a limited, non-exclusive,
          sublicensable, worldwide, fully paid, royalty-free license to use,
          modify, publicly perform, publicly display, reproduce, and distribute
          such materials on any and all media now known or developed in the
          future for the purpose of hosting, indexing, caching, distributing,
          tagging, marketing, and for all other lawful purposes without any
          requirement to make payment or obtain permission from you or any third
          party. You represent and warrant that you own or have a valid license
          to use any and all Submitted materials and otherwise have the right to
          grant the license set forth herein, and that the display, publication,
          or posting of any Submitted materials does not and will not violate
          the confidentiality rights, advertising rights, copyright, trademark
          rights, patents, contract rights, or any other intellectual property
          rights or other rights of any physical or legal person.
        </p>
        <p className="termsSubtitle">3.5. Right to Monitor.</p>
        <p className="termsText">
          We reserve the right to monitor your account at our discretion.
        </p>
        <p className="termsSubtitle">3.6. User Verification.</p>
        <p className="termsText">
          Users wishing to avail themselves of the Services may be asked to
          provide a valid mobile phone number through the ANNA application and
          verify this number according to our instructions. To verify your
          mobile phone number, we will send you a code in a text message to the
          phone number you provided, and you must enter this code as indicated
          in the ANNA app. If you change your mobile phone number, you must
          promptly provide and verify your new mobile phone number. By providing
          your mobile phone number, you explicitly agree to receive direct-dial
          calls, automatic calls, and prerecorded messages, as well as text
          messages to this number. Users wishing to participate in the Services
          may also be required to verify their identity, especially in the case
          of Payment services. This may include, but is not limited to,
          providing proof of identity (e.g., with a passport or other document
          identifying the person with a photograph) and proof of residence
          (e.g., with a current utility bill). ANNA assumes no responsibility
          and cannot be held liable for the verification or non- verification of
          a User's identity. <br />
          Any personal information provided by you to ANNA as mentioned above
          will be stored and processed by ANNA in accordance with our Privacy
          Policy.
        </p>
        <p className="termsSubtitle">3.7. Account Settings.</p>
        <p className="termsText">
          You can set certain default preferences for using payment services
          through the ANNA app, such as the preferred account for the payment
          card. You can modify these settings through the ANNA app.
        </p>
        <p className="termsSubtitle">
          3.8. Use of Booking Services through Third-Party Platforms.
        </p>
        <p className="termsText">
          ANNA collaborates with third-party websites, applications, and
          services (each a "Third-Party Platform") through which our Booking
          Services and Payment Services may be provided. According to our
          Privacy Policy, if the Client does not opt out by contacting us via
          email, we may contact Clients accessing or otherwise using our Booking
          Services and/or Payment Services through such Third-Party Platforms
          via the email address or phone number provided by the Client to such
          Third-Party Platform.
        </p>
        <p className="termsSubtitle">
          3.9. Small and Medium Business (SMB) Customer Reviews.
        </p>
        <p className="termsText mb-3">
          Clients booking services with SMBs through ANNA may have the
          opportunity to leave a review ("Review") for the SMB. In addition to
          complying with all review requirements that may be set by the Federal
          Trade Commission ("FTC"), the rules for posting and removal of reviews
          are as follows: <br />
          - ANNA is not responsible for the content of any review posted by the
          Client; - A Client may only provide a Review for a completed meeting.
          An SMB may request removal of a review only if the SMB marks the
          meeting as a no-show within 24 hours of the scheduled meeting;
          <br />
          - The Review should relate solely to the professional services
          provided by the SMB and be based solely on the Client's personal
          experience;
          <br />
          - Clients must not post Reviews that: (i) are untrue or intentionally
          misleading, (ii) contain harmful, defamatory, or unethical content or
          violate the law, personal rights, including no vulgar or offensive
          language, references to private or family life, or calls for violence
          or hatred, including racial, religious, or ethnic hatred or against
          sexual minorities;
          <br />
          - ANNA may refuse to publish a Review or remove an already published
          Review if, in ANNA's sole opinion, it violates the Terms of Use or
          legal provisions. However, ANNA does not resolve disputes between SMBs
          and Clients and does not verify the accuracy of Reviews;
          <br />
          - Removal of a Review is irreversible;
          <br />
          - Clients have the opportunity to express their opinion about the
          services provided by the SMB. Therefore, requests to block the ability
          to leave Reviews or delete Reviews published by Clients will not be
          considered unless the Review otherwise violates these Terms.
          <br />
          Reviews submitted by Clients who used the services of a specific SMB
          through booking made in the ANNA app are labeled with "Verified ANNA
          User." Regarding these Reviews, ANNA guarantees that they originate
          exclusively from the accounts of Clients who used the services of a
          specific SMB. Only after providing the SMB service do Clients have the
          opportunity to publish Reviews. Regarding Reviews without the
          "Verified ANNA User" tag, ANNA does not guarantee that such opinions
          come from Clients who used the services of a specific SMB.
        </p>
        <p className="termsSubtitle">
          Part IV – Terms of Booking Services, Payment Services, and SMB Rating
          in Search Results.
        </p>
        <p className="termsSubtitle">4.1. Appointment Scheduling.</p>
        <p className="termsText">
          ANNA provides Users with Booking Services to assist Clients in finding
          and scheduling appointments with various SMB participants and to help
          SMBs manage their calendars more effectively and increase their
          customer base. In response to a Client's online appointment request
          for an SMB through the ANNA website or ANNA app, ANNA directly
          connects with the SMB's computerized appointment database. The
          availability of appointments is determined at the time of the User's
          request and is provided by the SMB. Users understand and agree that
          ANNA does not verify the availability of independent SMBs and cannot
          be held responsible for errors in the SMB's calendar, such as double
          bookings or appointments that are not fulfilled by the SMB. After
          scheduling an appointment through the ANNA website or ANNA app, ANNA
          will provide confirmation of the appointment to both the Client and
          the SMB via SMS or PUSH notification.
          <br />
          By using Booking Services, Users agree to receive confirmation of
          appointments and reminders by email, SMS, push notifications, and/or
          any technology associated with chatbot or voice support that ANNA may
          use. ANNA is not responsible if the confirmation or reminder is not
          sent or received by the User.
        </p>
        <p className="termsSubtitle">
          4.2. No-Show and Appointment Cancellation Policy.
        </p>
        <p className="termsText">
          ANNA strives to provide quality services to Clients and small and
          medium businesses (SMBs). To help us maintain consistently high levels
          of service for SMBs and their clients, Clients must make all
          reasonable efforts to cancel any appointments they cannot attend as
          soon as possible before the meeting. You can cancel your appointment
          through the ANNA website or ANNA app.
          <br />
          Appointments cannot be canceled by directly calling the SMB. Some
          small and medium businesses may require a debit or credit card number
          to complete the appointment booking and reserve the SMB's calendar. To
          use the Booking Services for these SMBs, you must provide valid debit
          or credit card information, and a certain amount may be blocked or
          pre-paid. You may need to cancel the appointment according to the
          SMB's stated cancellation policy, which will be disclosed during the
          appointment scheduling. ANNA uses this debit or credit card
          information or service for payment processing, as described in our
          Privacy Policy, and is not responsible for any charges incurred from
          the debit or credit card account or other payment services due to any
          inability to cancel your appointment according to the SMB's
          cancellation policy. Note that cancellation fees may be charged only
          if the Client cancels the appointment independently using the
          processes described above, or the SMB records a no- show. <br />
          Please note: you may be charged a cancellation fee specified during
          the booking process, or you may lose the blocked or prepaid amount if
          you organize a Booking Service but cancel it late or do not show up,
          thus violating the SMB's stated cancellation policy. Unless otherwise
          specified, reimbursement of any blocked or prepaid amount rests solely
          with the SMB. ANNA is not responsible for reimbursing such amounts to
          Clients, and ANNA is not responsible for verifying whether the Client
          provided accurate debit or credit card information or whether the
          Client has sufficient funds in the bank account to complete the
          transaction.
          <br />
          Clients who cancel or no-show on multiple bookings may be blocked at
          the discretion of ANNA if such account activity harms the orderly
          operation of the Booking Service for small and medium businesses and
          the ANNA community members.
        </p>
        <p className="termsSubtitle">4.3. Terms of Use.</p>
        <p className="termsText">
          The User agrees to use the Booking Services only to schedule
          appointments with SMBs and then attend these appointments, arriving at
          the SMB on time and paying for the booked and provided services.
          Reselling or attempting to resell appointments is prohibited and is
          grounds, among other things, for canceling your appointments or
          terminating your access to the Services.
        </p>
        <p className="termsSubtitle">4.4. ANNA Payment Services, General.</p>
        <p className="termsText">
          ANNA may offer third-party payment services ("Payment Services") to
          allow Clients to pay bills at participating small and medium
          businesses through the ANNA Program. To use Payment Services at SMB
          participants, Clients must: (1) install the ANNA app on a compatible
          mobile device; (2) make a reservation for the SMB through Booking
          Services; (3) provide valid payment information through the ANNA app,
          as described below; and (4) have an account with a good reputation.
          ANNA does not charge the Client any fees for using Payment Services;
          however, standard text message and data rates may apply to the
          Client's mobile carrier; you are responsible for any fees charged by
          your mobile carrier in connection with your use of payment services
          and the ANNA app.
        </p>
        <p className="termsSubtitle">4.5. Sale Prices.</p>
        <p className="termsText">
          Prices and payment terms for SMB services are displayed during booking
          through the ANNA app; however, small and medium businesses may change
          these fees at their discretion. The ANNA website and ANNA app support
          both inclusive and exclusive tax systems. In the former, the prices
          presented are final prices, including all sales taxes, and in the
          latter, such taxes will be added to the final price after the service
          is provided. The decision on which system to use is made by the SMB.
        </p>
        <p className="termsSubtitle">4.6. Payment Card Information.</p>
        <p className="termsText">
          To use Payment Services, Clients must provide information about at
          least one valid debit or credit card or other payment systems through
          the ANNA app. ANNA uses this account information as described in our
          Privacy Policy. You can add, remove, and edit debit or credit card
          information you have provided through the ANNA app from time to time.
          If you provide account information for multiple valid debit or credit
          cards, you must choose which debit or credit card to use for payment.{" "}
          <br />
          To confirm that the payment card information you provided is accurate,
          we may temporarily authorize $1.00 USD on your debit or credit card
          when providing payment card information through the ANNA app. When we
          verify the accuracy of your payment card information (usually within a
          few days), the $1.00 USD hold will be canceled. In no case will you be
          charged a fee for this $1.00 USD authorization on your payment card.
          <br />
          To the extent permitted by applicable law and in accordance with our
          Privacy Policy, you acknowledge and agree that we may use certain
          third-party providers and service providers (such as payment method
          providers) to process payments and manage debit and credit card
          information.
          <br />
          By providing debit or credit card account information, IBAN, etc.,
          through the ANNA app, you represent, warrant, and agree that: (1) you
          have the legal right to provide us with such information; (2) you have
          the legal right to make payments from debit or credit card accounts;
          and (3) such action does not violate the terms and conditions
          applicable to your use of such debit or credit card accounts or
          applicable law. When you authorize payment using a debit or credit
          card account through the ANNA app, you represent, warrant, and
          undertake that sufficient funds or credit are available to make the
          payment using the debit or credit card account. By using Booking
          Services, you acknowledge and accept the binding agreement to assume
          full financial responsibility for all Booking Services you plan
          through ANNA or information contained on ANNA.
        </p>
        <p className="termsSubtitle">
          4.7. Payment Authorization and Settlement.
        </p>
        <p className="termsText">
          When you specify through the ANNA app that you intend to pay your bill
          using Payment Services, you authorize ANNA or the payment service
          provider to charge your debit or credit card the full amount of the
          services to be provided and any cancellation fees that may arise at
          any time before or after the scheduled appointment date and time. You
          are responsible for the timely payment of all amounts owed to ANNA and
          the SMB.
        </p>
        <p className="termsSubtitle">4.8. Receipts and Transaction History.</p>
        <p className="termsText">
          All transaction receipts related to payment services are visible in
          your account on the ANNA app and/or on the ANNA website. If you wish
          to receive an email or paper copy of a receipt, you must make a
          request.
        </p>
        <p className="termsSubtitle">4.9. Incomplete Payments.</p>
        <p className="termsText">
          ANNA is not responsible for any payments that Payment Services do not
          execute due to: (1) insufficient funds on your debit or credit card
          account to complete the transaction or the transaction will exceed the
          credit limit or overdraft protection of the debit or credit card
          account; (2) you did not provide us with correct payment account
          information; (3) the expiration date of your debit or credit card has
          passed; or (4) circumstances beyond our control (such as, but not
          limited to, power outages, disruptions in cellular communication, or
          any other interference by third-party forces) prevent the transaction
          from being executed. To the extent that any outstanding amounts cannot
          be collected from your debit or credit card account using Payment
          Services, you are fully responsible for paying the relevant SMB by
          other means, such as cash, in accordance with the full cost of the
          scheduled and/or provided services.
        </p>
        <p className="termsSubtitle">
          4.10. ANNA, Alternative Payment Methods.
        </p>
        <p className="termsText">
          ANNA, in collaboration with third-party payment methods, also offers
          its own payment method ("ANNA PAY").
        </p>
        <p className="termsSubtitle">4.11. SMB Ratings in Search Results.</p>
        <p className="termsText mb-3">
          In the event that a Client is searching for services or SMBs, the
          Client is presented with profiles of SMBs that meet all the search
          criteria specified by the Client. The customer can use filters
          ("Special Offers," "Mobile Services," "Online Services") and location
          to narrow down search results. The client can also sort profiles of
          small and medium-sized businesses by criteria such as "Sponsored
          First," "Distance" (closest first), or "Reviews" (highest first). The
          main parameters determining the positioning of SMB profiles in the
          Client's search results are: <br />
          - Region: SMB profiles that match the location criteria specified by
          the Client or the location provided by the Client via GPS are
          presented to the Client.
          <br />
          - Distance: SMB profiles located closest to the Client are presented
          to the Client.
          <br />
          - Availability: SMB profiles with available time slots for the service
          that interests the Client are presented to the Client.
          <br />
          - Business category: SMBs can indicate whether a specific service
          belongs to the primary or additional business category. If the Client
          is looking for a particular service, profiles of SMBs that have
          identified the sought-after service as the primary business category
          are displayed to the Client first.
          <br />
          - Service type: SMBs can specify the type of a particular service. If
          the Client is looking for a specific service and selects its type,
          profiles of SMBs that have indicated the same type of service will be
          presented to the Client first.
          <br />
          - Ratings: Profiles of SMBs with the highest ratings based on reviews
          from other Clients are presented to the Client.
          <br />
          - Gender: Profiles of SMBs adapted to the gender specified by the
          Client are presented to the Client.
          <br />
          - Promotions: Profiles of SMBs offering the highest promotions at the
          time of the search are presented to the Client.
          <br />
          - Profile view-to-completion ratio: ANNA analyzes the proportion of
          the number of profile views to the number of completed visits to a
          specific SMB. Profiles of SMBs with the highest value in this aspect
          are presented to the Client.
          <br />
          - Completed visits booked by the Client: Profiles of SMBs with the
          highest number of completed visits booked by the Client through the
          App are presented to the Client.
          <br />- Repeat visits: Profiles of SMBs with the highest number of
          repeat visits booked by different clients are presented to the Client.
          Purchasing an additional service from ANNA by small and medium-sized
          businesses, aimed at supporting small and medium-sized businesses in
          attracting new Clients, may also affect the search results displayed
          to the Client. Profiles of SMBs that have utilized this additional
          service will be prioritized in the Client's search results.
        </p>
        <p className="termsSubtitle">Part VI – Terms of Use for SMBs.</p>
        <p className="termsText">
          In addition to other conditions stated in these Terms of Use, the
          following Terms of Use for SMBs apply to all SMBs.
        </p>
        <p className="termsSubtitle">6.1. Unauthorized Business and Content.</p>
        <p className="termsText">
          In addition to the prohibited uses mentioned above, our ANNA program
          and ANNA website cannot be used by SMBs to: <br />- Publish,
          distribute, offer, sell, or compel us to do so on their behalf any
          content, products, or services directly or indirectly related to: (
          <b>"Unauthorized Business" </b> and <b>"Unauthorized Content"</b>):
          <br />
          (1) Illegal or contrary to good morals or principles of social
          interaction; (2) Drugs, tobacco, alcohol, other stimulants, and
          substances intended for their production;
          <br />
          (3) Pornographic, sexual (including escort or tantra), sexually
          suggestive content aimed at promoting a specific service or product
          through sexually explicit, ambiguous, or unethical content;
          <br />
          (4) Entertainment for adults; and orders via the Internet/mail/phone
          for age- restricted goods;
          <br />
          (5) Sale of firearms, ammunition, or weapons and other devices
          designed to cause bodily harm;
          <br />
          (6) Betting, including lottery tickets, sports-related gambling,
          casinos; (7) Insurance or other financial goods; money transfers;
          services of an independent financial consultant or securities; pyramid
          sales or multi-level marketing;
          <br />
          (8) Forgery or infringement of the intellectual property rights of
          third parties (including illegal software or downloads);
          <br />
          (9) Pharmacies or pharmacy services redirecting any other sales of
          products or services in strictly regulated areas;
          <br />
          (10) Hatred or harmful actions, calls or support for discrimination,
          violence, or terrorism.
          <br />
          Violation of this requirement may result (at the sole discretion of
          ANNA and without the need for any additional notice) ("
          <b>Corrective Actions"</b>): (i) blocking or removing any unwanted
          content, (ii) temporary or permanent blocking of certain features or
          services you use, (iii) temporary or permanent blocking of your SMB
          account, (iv) suspension of the SMB account. The initiation of any
          Corrective Actions by ANNA, including the suspension of the Account,
          does not exempt you from the obligation to pay the expenses incurred
          for ANNA Services until a certain Corrective Action.
        </p>
        <p className="termsSubtitle">6.2. SMB Accounts, Generally.</p>
        <p className="termsText">
          To use our services as an SMB, you must create an account on the ANNA
          website or in the ANNA application ("SMB Account"). By opening an SMB
          Account, you agree to comply with the Terms of Use, Privacy Policy,
          and agree to provide users with the services they have booked from
          you, in accordance with the Terms of Use. Additionally, the conditions
          and limitations outlined in sections 3.2–3.6 of these Terms regarding
          customer accounts equally apply to SMB accounts. <br />
          By registering an SMB Account, you confirm that you have the authority
          to act on behalf of the SMB and must provide truthful, accurate,
          current, and complete information about the registered SMB. This
          information may include Know Your Customer (KYC) data as required by
          our payment processor.
          <br />
          You also agree to promptly update the small and medium-sized business
          account to keep it accurate, current, and complete. You are fully
          responsible for maintaining the confidentiality of your SMB Account
          and information in your SMB Account, and except as required by
          applicable law, you are fully responsible for any use of your SMB
          Account, whether authorized by you or not. You agree to promptly
          notify ANNA of any unauthorized use of your SMB Account or any other
          security breach related to your use of the services.
        </p>
        <p className="termsSubtitle">6.3. Registration, Services.</p>
        <p className="termsText">
          Registration of an SMB Account may be free or subject to a
          registration fee depending on the region of SMB business activity or
          the type of services provided. Therefore, you may be required to pay a
          registration fee for the SMB Account ("Registration Fee"). The exact
          amount of the registration fee will depend on the type and location of
          your SMB. ANNA reserves the right to change the location or types of
          small and medium-sized enterprises receiving benefits of reduced or
          waived registration fees from time to time at its discretion.
          <br />
          The registration fee is payable using acceptable payment methods
          specified during registration or otherwise communicated to the SMB. In
          addition to the registration fee, you agree that a certain fee will be
          debited periodically, monthly or semi-annually, depending on your
          agreement with ANNA, from the accepted payment method for placing your
          offerings through the services and maintaining SMB Account support
          ("SMB Subscription Fee"). The first SMB subscription fee will be
          debited at the time of registration along with the registration fee,
          if any. ANNA reserves the right, at its discretion, to cancel or
          reduce the SMB subscription fee for a certain period, such as during a
          free trial period. After this period, you will be offered a specific
          SMB subscription fee, and the corresponding amount will be debited
          from your account if you agree to the terms. ANNA reserves the right
          to block or delete SMB accounts that do not remit the required fees.
          <br />
          You are required to inform ANNA of the true and actual number of
          employees designated for your small and medium-sized business account
          and to update this information promptly without any additional
          requests. Failure to do so may result in the removal of additional
          employees not covered by ANNA payments from your small and
          medium-sized business account, or your SMB account may be blocked or
          terminated (at the discretion of ANNA).
        </p>
        <p className="termsSubtitle">
          6.4. Creating an account on a third-party platform.
        </p>
        <p className="termsText">
          ANNA collaborates with third-party websites, applications, and
          services (each referred to as a "Third-Party Platform") through which
          our Booking Services and Payment Services may be provided. In
          accordance with our Privacy Policy, we may automatically create a SMB
          profile on such third-party platforms using the information provided
          to us by the SMB during the creation of their ANNA SMB account. This
          information may include the business name, address, phone number,
          email address, working hours, availability schedule, offered services,
          price list, reviews, and photos. By using the Services, you grant us a
          license to use and disclose this information on such third-party
          platforms, and you warrant that the information provided by you will
          not violate the rights of any third party. The use of third-party
          platforms and their accounts is governed by their own privacy policy
          and is not covered by these Terms or our Privacy Policy.
        </p>
        <p className="termsSubtitle">6.5. Canceling the SMB account.</p>
        <p className="termsText">
          You can cancel your SMB account through the ANNA mobile app or by
          contacting ANNA via email. However, ANNA will not refund you for any
          already paid period, even if you did not use your SMB account during
          that period, did not receive any bookings, or your offer was not
          listed in the Services. In case of cancellation, we reserve the right
          to remove your small and medium-sized business account from our
          Services in accordance with our Privacy Policy.
          <br />
          Please note that canceling the SMB account on ANNA does not cancel or
          cause the deletion of the SMB account on a third-party platform. To
          cancel or request the deletion of such accounts, small and
          medium-sized businesses should directly contact the respective
          third-party platform.
        </p>
        <p className="termsSubtitle">6.6. Compliance with the Terms of Sale.</p>
        <p className="termsText">
          All sales and accepted bookings are binding. The SMB agrees and
          acknowledges that they, not us, are fully responsible for providing
          any and all purchased services to Customers, and that a purchase-sale
          agreement arises when the Customer uses Booking Services to reserve a
          service. Any sale is governed by applicable laws but does not include
          any right that is not a legal right and is not outlined in these
          Terms.
        </p>
        <p className="termsSubtitle">6.7. SMB Guarantees; Limitations.</p>
        <p className="termsText">
          In addition to Unauthorized Business and Prohibited Use of Services
          outlined in these Terms, the SMB guarantees that any services: (a) are
          not: illegal, indecent, offensive, threatening, defamatory, infringing
          on confidentiality, violating intellectual property rights, or
          otherwise harmful to any third party; (b) have not been identified by
          the U.S. Consumer Product Safety Commission and any other rules set by
          small and medium-sized business countries and the Customer as unsafe
          for consumers and therefore are not subject to recall; (c) are not
          counterfeit; (d) do not violate the laws of any country whose citizens
          may purchase or receive the services; and (e) do not violate the rules
          and regulations published by the U.S. Food and Drug Administration
          (FDA). The SMB also guarantees that they, he, she, or it owns all
          copyrights, trademarks, and other intellectual property associated
          with any information in the SMB account provided to us, or that the
          SMB has the permission of the intellectual property owner (a) to place
          the Product(s) in commerce; (b) to receive net proceeds from such
          arising sales; and (c) to protect intellectual property in the
          Product(s). Finally, the SMB guarantees and represents that they, he,
          she, or it has all licenses and authorizations necessary to comply
          with these Terms. Upon our request, the SMB will provide us with
          sufficient documentary evidence of such permission or refusal. We are
          not obligated to request such documentary evidence, and the fact that
          we did not request such documentary evidence in no way constitutes a
          statement of whether such permission or refusal is required. <br />
          Additionally, the use of slang related to drug use is prohibited when
          referring to products available for sale on the ANNA website. All
          government officials, agencies, and/or agents must identify themselves
          to us when entering the ANNA site and when ordering any products from
          the ANNA site.
        </p>
        <p className="termsSubtitle">6.8. Listing Description.</p>
        <p className="termsText">
          When listing services available for booking through Booking Services,
          the SMB must accurately describe the services they provide and all
          terms of sale. Small and medium-sized business listings may only
          include textual descriptions and other content related to the sale of
          that service and must be categorized appropriately with relevant tags.
        </p>
        <p className="termsSubtitle">6.9. Calendar Management.</p>
        <p className="termsText">
          The SMB is responsible for accurately maintaining and updating their
          calendar to reflect current and correct availability for Booking
          Services. Under no circumstances is ANNA responsible for double
          bookings or other scheduling errors.
        </p>
        <p className="termsSubtitle">6.10. Customer Communication.</p>
        <p className="termsText">
          Small and medium-sized businesses must employ ethical sales tactics
          when promoting any services, refraining from making false and/or
          deceptive statements about services and/or the small and medium-sized
          business. Small and medium-sized businesses explicitly agree to always
          conduct themselves ethically, morally, and legally. To assist with
          this, ANNA may, at its discretion, periodically impose restrictions on
          the types of messages SMBs can send to Customers, including but not
          limited to mass targeted marketing campaigns via SMS, email, or push
          notifications. In addition to the above, SMBs are prohibited from
          abusing any communication system available through the ANNA program or
          ANNA website, including the use of autoresponders. SMBs understand
          that such autoresponders may or may not be activated automatically
          after SMB account registration, depending on jurisdiction, and may be
          modified by the SMB through account settings.
        </p>
        <p className="termsSubtitle">6.11. Compliance with the Law.</p>
        <p className="termsText">
          In addition to other requirements for compliance with laws,
          regulations, and terms outlined in this document, small and
          medium-sized businesses agree to adhere to any and all local, state,
          and federal regulations and laws related to customer communication and
          sales.
        </p>
        <p className="termsSubtitle">6.12. Freedom of Marketing Methods.</p>
        <p className="termsText">
          Notwithstanding the above, small and medium-sized businesses have the
          right to choose on which social media platforms and through other mass
          media means and sales channels they will promote their services and
          use the Services, including the ability to choose their own tools,
          methods, locations, and working hours.
        </p>
        <p className="termsSubtitle">6.13. Right to Refuse.</p>
        <p className="termsText">
          Small and medium-sized businesses have the right to refuse to accept
          bookings made through Booking Services for any reason or no reason at
          all, provided that such refusal does not violate federal, state, or
          local laws or any other rules or regulations.
        </p>
        <p className="termsSubtitle">6.14. Policy, Mandatory Sales Terms.</p>
        <p className="termsText">
          Before being allowed to conduct any transactions through the Services,
          small and medium-sized businesses must implement the following
          policies within their service: a prepayment policy, a cancellation
          policy, a return policy, and a payment policy, in addition to any
          other policies desired by the small and medium-sized business, which
          shall be clearly outlined. Small and medium- sized businesses must
          conscientiously create reasonable policies and adhere to them. In the
          event that a policy does not comply with these Terms of Use and/or is
          deemed unjustified in any way, we may, at our discretion, require the
          SMB to review that policy.
        </p>
        <p className="termsSubtitle">
          6.15. Processing Fee for Small and Medium-sized Businesses.
        </p>
        <p className="termsText">
          Small and medium-sized businesses wishing to use our payment services
          are subject to a payment processing fee based on the current local
          rates visible in the ANNA program ("Processing Fee"). For detailed
          information, contact us at office@incomm.space. Such processing fee
          may be changed without notice at our sole discretion. This processing
          fee is non-refundable for any reason, including situations where the
          small and medium-sized business is required to refund a purchase to
          the Customer.
        </p>
        <p className="termsSubtitle">6.16. Refunds and Chargebacks.</p>
        <p className="termsText">
          If a Customer is not an authorized user of the payment method or
          otherwise disputes the transaction, the transaction amount may be
          canceled or charged back provided that the transaction: (a) is
          disputed, (b) is canceled for any reason by the payment network,
          payment processor, ANNA, or the Customer or their financial
          institution, (c) is not authorized, or ANNA has any reason to believe
          the transaction was not authorized, or (d) is likely illegal,
          suspicious, or violates these Terms. For any transaction resulting in
          a chargeback, ANNA will withhold the chargeback amount in reserve from
          the amounts due to the SMB. We may reimburse the amount of any
          chargeback and any related fees and penalties imposed by the network
          or our processor. In addition, note that ANNA may impose a processing
          fee for chargebacks. If you have unresolved chargebacks, we may
          withhold payments, and if we reasonably believe that a chargeback is
          likely for any transaction, we may withhold the potential chargeback
          amount from payments otherwise due to you until: (a) the chargeback is
          assessed through a Customer complaint, in which case we withhold the
          funds; (b) the period of time according to applicable law or
          regulations within which your Customer may dispute the transaction has
          passed; or (c) we determine that a chargeback for the transaction will
          not occur. If we cannot recover the funds related to a chargeback for
          which you are responsible, you agree to pay us the full amount of the
          chargeback immediately upon demand. You agree to pay all costs,
          including attorney's fees and other legal expenses incurred by us to
          recover all unpaid amounts.
          <br />
          If we believe that you may experience or are experiencing an excessive
          number of chargebacks, we may impose additional conditions governing
          your account, including (a) the establishment of new processing fees,
          (b) the creation of a reserve in an amount reasonably determined by us
          to cover anticipated chargebacks and associated fees, (c) delayed
          payments, or (d) termination or suspension of Payment Services. <br />
          Upon request and at your expense, we will assist you in investigating
          any of your transactions processed through Payment Services. For this
          purpose, you allow us to share information about the chargeback with
          the Customer, the Customer's financial institution, our payment
          processor, payment networks, and your financial institution for
          investigation and/or mediation of the chargeback. We will require you
          to provide necessary information to dispute a chargeback. If a
          chargeback is successfully disputed, we will refund the corresponding
          reserved funds to your account. If the network or issuing bank does
          not resolve the dispute in your favor or you choose not to dispute the
          chargeback, we may refund the amount of the chargeback and any
          associated fees as described in these terms. You acknowledge that your
          inability to promptly assist us in investigating a transaction,
          including providing necessary documentation within seven (7) calendar
          days of our request, may result in irreversible chargeback.
        </p>
        <p className="termsSubtitle">6.17. Funds Withholding.</p>
        <p className="termsText">
          If, in our reasonable opinion, we are obligated to do so by law, we
          reserve the right to withhold funds for services that we reasonably
          consider suspicious of money laundering, unauthorized business, fraud,
          or any other illegal activity, or in the case of any other chargebacks
          or reversals.
        </p>
        <p className="termsSubtitle">6.18. Payouts.</p>
        <p className="termsText">
          After creating an SMB account, using a secure form, you will have the
          opportunity to specify a deposit account to receive payouts. Once
          funds for Payment Services are completed and paid out, they will be
          available for withdrawal. Typically, payouts are automatically
          transferred to your deposit account net of fees daily. For all other
          small and medium-sized companies, payouts are usually processed to
          your designated deposit account net of fees within 2-3 business days.
          <br />
          Except as required by law, you are solely responsible for maintaining
          accurate records of all transactions processed through the settings of
          your SMB account. We are not responsible for any payout delays. To
          learn about the payout schedule for any specific transaction, please
          review the payment report or contact ANNA.
          <br />
          In the event your payout account goes negative, you agree that we have
          the right to offset any incoming payments against the negative
          balance. If you fail to bring your account into a positive balance, we
          may block further payments to you and initiate legal proceedings for
          recovery.
        </p>
        <p className="termsSubtitle">
          6.19. Responsibility for Accurate Payout Information.
        </p>
        <p className="termsText">
          You agree to provide current, complete, and accurate information about
          the deposit account for payouts. You agree to promptly update your SMB
          account and other information, including your address, email, and
          banking information, so that we can complete your transactions and
          contact you as needed.
        </p>
        <p className="termsSubtitle">6.20. Deposits.</p>
        <p className="termsText">
          ANNA may require small and medium-sized businesses to maintain
          deposits in a deposit account to act as collateral against chargebacks
          and refunds. The amount of such deposits will be determined by ANNA.
        </p>
        <p className="termsSubtitle">6.21. Processing Errors.</p>
        <p className="termsText">
          We will attempt to rectify any processing errors identified. If an
          error resulted in you receiving less funds than you were entitled to,
          we will credit the difference to your SMB account. If an error results
          in you receiving more funds than you were entitled to, ANNA will debit
          the additional funds from your SMB account or associated bank account.
          You must reimburse ANNA the difference within thirty (30) calendar
          days of receiving the demand. We will rectify transactions that you
          process incorrectly if you notify us of such an error. If you fail to
          notify us of a processing error within thirty (30) calendar days of
          when it first appeared in your transaction history, it may be
          considered a waiver of any rights to amounts owed to you.
        </p>
        <p className="termsSubtitle">6.22. Refunds.</p>
        <p className="termsText">
          By accepting card transactions through Payment Services, you agree to
          process refunds and provide compensation and adjustments for your
          goods or services in accordance with these Terms. You are required to
          disclose your refund or cancellation policy to customers at the time
          of purchase and/or booking, if applicable, and may refund transactions
          within thirty (30) calendar days from the transaction date. The
          refund/adjustment amount must include any applicable taxes that need
          to be refunded and cannot exceed the amount shown as the total in the
          initial sales data, except for the exact amount needed to reimburse
          shipping costs that the customer paid to return the item. If your
          refund policy prohibits returns or is unsatisfactory to the customer,
          you may still receive chargebacks associated with such sales. <br />
          ANNA will automatically process refunds on your behalf from the
          available balance or deposit amount and is not responsible for refunds
          made in error or in violation of your own rules. If your available
          balance or deposit amount is insufficient to cover the refund, ANNA
          will deduct the requested refund amount and return it to your
          customer. Additional refunds may be accepted but will only be
          processed once your account shows a positive balance. For each refund,
          ANNA may charge a refund processing fee.
          <br />
          ANNA is not obligated to accept any returns of your goods or services
          on your behalf in accordance with the applicable Network Rules (as
          defined below). By using Payment Services, you agree to comply with
          all relevant statutes, rules, and regulations set forth by the payment
          processing networks.
        </p>
        <p className="termsSubtitle">6.23. Processing.</p>
        <p className="termsText">
          We have the right to set off any claims against the sums SMB pays
          ANNA. Additionally, to the maximum extent permitted by law, we may
          recover any and all your obligations to us by offsetting them from
          incomplete transactions in the deposit account. A fee is charged
          during transaction processing and is deducted from the funds received.
          Failure to pay all due amounts promptly constitutes an immediate
          breach of this Agreement for which you are responsible. Furthermore,
          you agree to pay all fees and expenses, including but not limited to
          attorney fees, incurred by us or on our behalf, arising from or
          related to the collection of any unpaid obligations by you.
          <br />
          In addition to any other fees outlined in these Terms, ANNA may also
          charge standard ANNA rates to small and medium-sized businesses for
          investigations, including but not limited to (i) investigations
          necessary to respond to any third- party court or government subpoena,
          collection, or account arrest on the small and medium-sized business
          account, as well as (2) investigations and activities necessary to
          verify and enforce any payee changes in accordance with the Uniform
          Commercial Code (as described below) or court order.
          <br />
          You also understand that under Section 9-406 of the Uniform Commercial
          Code, we may be required to fully or partially pay your secured
          party's (e.g., creditor) assignment and payment direction after
          receiving notice. If we receive such notice and direction, we will,
          after deducting our fees and compliance expenses as specified above,
          make payments to your secured party as instructed without prior
          notice.
        </p>
        <p className="termsSubtitle">6.24. Tax Payments.</p>
        <p className="termsText">
          You are responsible for determining any taxes imposed, payable, or
          required to be withheld, paid, or collected for any reason for your
          use of Payment Services ("Taxes"). You are also fully responsible for
          collecting, withholding, reporting, and remitting any applicable taxes
          to the relevant tax authority. We are not obligated and will not
          determine whether taxes apply or calculate, collect, report, or remit
          any taxes to any tax authority arising from any transaction. If you
          are a small and medium-sized business representative in the United
          States and, during a particular calendar year, you process (i) a total
          payment amount exceeding $20,000 USD and (ii) more than two hundred
          (200) payments, ANNA or its payment processor may be required by law
          to report information about you and your use of Payment Services to
          the Internal Revenue Service ("IRS"). To determine whether you have
          reached the IRS reporting threshold, the total amount does not include
          any adjustments for credits, cash equivalents, discount amounts,
          commissions, reimbursed amounts, or any other amounts. By reviewing
          the information of our payment service related to your small and
          medium-sized business account, it will be determined whether you reach
          payments of $20,000 USD or more than two hundred (200) payments. To
          fulfill these regulatory obligations before SMB reaches the threshold
          values, we will need additional information, including a completed IRS
          W9 form for SMB in the U.S. We reserve the right to block Payment
          Services for small and medium- sized businesses that do not provide us
          with all necessary information in accordance with applicable tax rules
          and regulations.
        </p>
        <p className="termsSubtitle">6.25. Additional Products and Services.</p>
        <p className="termsText">
          From time to time, we may offer additional products and services for
          purchase by small and medium-sized businesses, such as extra text
          messages, hardware for payment processing, technical support, and lead
          generation. Additional service fees apply for such products and
          services, and these fees are set at the point of sale, subject to
          change at our sole discretion and outlined in a separate agreement
          between the Customer and ANNA or a proposal from ANNA or its branch
          for such products and services. In the event that such additional
          services include lead generation for small and medium-sized
          businesses, SMB agrees and acknowledges that it is not responsible for
          paying ANNA a commission for Customers who first use Booking Services.
          In this case, ANNA and SMB agree in good faith on the specified number
          of leads generated from ANNA, reserving the right to determine the
          same.
        </p>
        <p className="termsSubtitle">6.26. Transaction History.</p>
        <p className="termsText mb-3">
          You can access your transaction history at any time through your
          account settings. This history may include the ability to track fees
          and reserves, as well as otherwise obtain statistical data and
          performance reports for your SMB. While we strive to keep all such
          information complete, timely, and accurate, we do not guarantee that
          the entire transaction history will be error-free. It is recommended
          that you keep your own transaction history and financial information
          and verify them with your corporate accountant. By using the ANNA
          program and ANNA website, you acknowledge that you rely on any such
          information provided by ANNA at your own risk. ANNA is not responsible
          for inaccurate or incomplete information.
        </p>
        <p className="termsSubtitle">
          Part VII – Intellectual Property; Confidentiality.
        </p>
        <p className="termsSubtitle">7.1 Intellectual Property Rights.</p>
        <p className="termsText">
          This Agreement for access to and use of Services, and these Terms of
          Service, do not grant you a license to any software or intellectual
          property. Services are protected by U.S. and Ukrainian laws, and where
          applicable, international intellectual property laws. Services belong
          to us and are the property of us or our licensors (if any). We retain
          all proprietary rights to Services. Additionally, all materials
          displayed or transmitted in Services, including but not limited to
          text, photographs, images, illustrations, video clips, audio
          recordings, and graphics (hereinafter "Materials"), belong to us and
          are protected by Ukrainian and international copyright, trademark,
          service mark, and other property rights, laws, and treaties. Except as
          expressly provided, you do not have the right to copy, reproduce,
          publish, transmit, transfer, sell, lease, modify, create derivative
          works, distribute, republish, perform, display, or otherwise
          commercially use the Materials transmitted in Services or violate any
          copyrights or other intellectual property rights contained in the
          Materials. You do not have the right to remove or alter, or induce
          others to remove or alter, any copyright, trademark, or other
          proprietary notices or visual marks and logos from the Materials. You
          may make a single printed copy of any Materials provided by us in
          Services solely for your personal, non-commercial use, provided that
          you do not remove or induce the removal of any copyright, trademark,
          or other proprietary notices or visual marks and logos from the
          Materials. You do not have the right to archive or store any Materials
          accessed through these Services without our express written
          permission. All requests for archiving, republication, or storage of
          any part of the Materials must be sent to us in writing and must
          clearly state the purpose and manner of use of the Material. Requests
          for permission to archive, store, or republish any part of the
          Materials can be sent to office@incomm.space. You do not receive any
          rights or licenses to the Materials other than the limited rights to
          use the Services as specified in these Terms of Service. Any Materials
          accessible or downloaded from this site must be accessed or downloaded
          in accordance with the Terms of Service outlined in this Agreement. We
          reserve any rights not expressly granted by these Terms of Service.
        </p>
        <p className="termsSubtitle">7.2 Feedback.</p>
        <p className="termsText">
          You may have the opportunity to provide reviews, suggestions, ideas,
          and feedback (collectively referred to as "Feedback"). If you provide
          such Feedback, you grant us the exclusive ownership right, including,
          without limitation, the right for us or any third party we designate
          to use, copy, transmit, extract, publish, distribute, publicly
          display, publicly perform, create derivative works, host, index,
          cache, tag, encode, modify, and adapt (including, without limitation,
          the right to adapt to streaming, downloading, broadcasting, mobile,
          digital, sketches, scans, or other technologies) in any form or medium
          known now or developed later. All such Feedback is considered
          non-confidential. If it is determined that you retain moral rights
          (including rights of attribution or integrity) in the content you
          submit, you declare that (a) you do not require the use of any
          personal information in connection with the content or any derivative
          works or updates; (b) you do not object to the publication, use,
          modification, deletion, and use of the content by us or our licensees,
          successors, and assigns; (c) you forever waive any moral rights to the
          content and agree not to demand or assert any moral rights; and (d)
          you forever release us and our licensees, successors, and assigns from
          any claims that you may otherwise assert against us on the basis of
          any such moral rights. You also permit any other User to access, view,
          store, or reproduce the content for personal use by that User.
          Notwithstanding the above, you acknowledge that your Feedback may
          contain concepts, ideas, materials, proposals, or suggestions
          concerning ANNA or its initiatives (hereinafter "Ideas"). Regarding
          your Ideas, you acknowledge that: (a) we receive numerous submissions
          from many parties, and/or may be developing or have developed ideas
          similar to your Ideas, and that our review of your Ideas is not an
          acknowledgment of novelty, priority, or originality; and (b) use by us
          of any ideas similar to your Ideas, whether based on your Feedback or
          Submissions, provided to us by third parties, or independently
          developed or considered by us, shall not obligate us to you.
        </p>
        <p className="termsSubtitle">7.3 User Licensing.</p>
        <p className="termsText">
          You agree to grant us an exclusive, worldwide, perpetual, irrevocable,
          royalty- free, sublicensable (through multiple tiers) right to use
          copyright, publicity, and database rights (but no other rights) that
          you have on any content contained in any listing, service, or profile.
          You agree to allow ANNA to store or reformat your content and display
          it in any way at our discretion. ANNA will use personal information
          only in accordance with our Privacy Policy.
        </p>
        <p className="termsSubtitle">7.4 Customer Confidential Information.</p>
        <p className="termsText">
          In the course of a transaction conducted through the ANNA program,
          small and medium-sized businesses may obtain personal information from
          the customer/user, including payment information, phone number, and
          email address. The exact extent of personal information collected by
          ANNA is described in our Privacy Policy. This personal information
          will only be used for this transaction or ANNA-related communication
          and will be kept in strict confidentiality according to our Privacy
          Policy. To learn more about how ANNA stores, processes, and for what
          purposes it uses personal information, please refer to our Privacy
          Policy. We have not granted you a license to use information for
          unsolicited commercial messages. Without limitation to the foregoing,
          without the explicit consent of the customer, small and medium-sized
          businesses are not licensed to add any customer to an email or
          physical mailing list. For additional information, review our Privacy
          Policy. This provision does not apply to cases where the small and
          medium-sized business adds its own customers to the Mobile App or
          otherwise obtains customer consent for communication with that
          specific customer for purposes unrelated to the transaction and ANNA
          communication.
        </p>
        <p className="termsSubtitle">7.5. ANNA Program License.</p>
        <p className="termsText">
          In accordance with the terms and conditions of this Agreement, ANNA
          grants the User a non-exclusive license, without the right to transfer
          and revoke, to use the ANNA Program solely in the form of object code
          on compatible User devices, including, but not limited to, mobile
          devices, exclusively to support the User's permitted use of the
          Services.
        </p>
        <p className="termsSubtitle">7.6. DCMA Notice of Violation.</p>
        <p className="termsText mb-3">
          If you believe that any Services violate your copyright, please notify
          our copyright agent in writing. The contact information for our
          copyright agent is provided below in this Section 7.6. To enable us to
          take action, your notice must: (a) provide your physical or electronic
          signature; (b) identify the work protected by copyright for which you
          believe rights are being violated; (c) identify the material that you
          believe violates the rights of your work, and provide sufficient
          information about its location for us to locate it; (d) provide us
          with a way to contact you, such as your address, phone number, or
          email; (e) state that you genuinely believe that the material you have
          identified as infringing is not authorized by the copyright owner, its
          agent, or the law to be used in connection with the Services; and (f)
          state that the information you provide in your notice is accurate, and
          that, under penalty of perjury, you are authorized to act on behalf of
          the copyright owner whose rights are being violated. Here is the
          contact information for our copyright agent: Copyright Protection
          Incomm-space Corp. USA PLEASE NOTE: We cannot take action until you
          provide us with all the necessary information.
        </p>
        <p className="termsSubtitle">
          Part VIII – Third-Party Advertising, Promotions, Platforms, and Links.
        </p>
        <p className="termsSubtitle">
          8.1. Third-Party Advertising and Promotions.
        </p>
        <p className="termsText">
          We may, from time to time, run advertisements and promotional
          activities from third parties in the Services. Your agreements or
          correspondence with other advertisers, as well as any terms,
          conditions, warranties, or representations related to such agreements
          or participation in promotions of other advertisers, are solely
          between you and such third party. We are not responsible for any
          losses or damages of any kind resulting from any such agreements or
          the presence of third-party advertisers in the Services.
        </p>
        <p className="termsSubtitle">
          8.2. Use of Third-Party Tools and Platforms.
        </p>
        <p className="termsText">
          We may provide you with access to third-party developer tools and
          integrations with third-party platforms that we do not control, have
          no control over, or participate in. Information is transmitted to
          third parties as indicated in our Privacy Policy. You acknowledge and
          agree that we provide access to such tools "as is" and "as available"
          without any warranties, representations, or conditions of any kind and
          without any endorsement. We shall not be liable for any damages
          arising out of or related to your use of additional third-party tools
          and third-party platforms. <br />
          Any use by you of additional tools offered through the Services is
          solely at your own risk and discretion, and you must ensure that you
          are familiar with and agree to the terms on which tools are provided
          by respective third-party providers.
          <br />
          In the future, we may also offer new services and/or features through
          the Services, including but not limited to the release of new tools.
          Such new features and/or services are also governed by these Terms of
          Use.
        </p>
        <p className="termsSubtitle">8.3. Third-Party Links.</p>
        <p className="termsText mb-3">
          Certain content, products, and services available through our Services
          may include materials from third parties. <br />
          Third-party links in the Services may direct you to third-party
          websites and/or services that are not affiliated with us. We do not
          undertake to review or assess the content or accuracy, and we do not
          guarantee and are not liable for any third-party materials or websites
          and/or services, or for any other materials, products, or services of
          third parties. <br />
          We are not responsible for any damages or losses related to the
          purchase or use of goods, services, resources, content, or any other
          transactions made in connection with any third party you connect with
          through the Services. Please carefully review the policy and practices
          of third parties and ensure that you understand them before engaging
          in any transactions. Complaints, claims, concerns, or questions
          regarding third-party products should be directed to third parties.
        </p>
        <p className="termsSubtitle">
          Part IX: Disclaimer; Limitation of Liability; Indemnification
        </p>
        <p className="termsSubtitle">
          9.1. Disclaimer of Warranty; Limitation of Liability.
        </p>
        <p className="termsText">
          You agree that you use the Services at your own risk. Neither we, nor
          our affiliates, nor any respective employees, agents, third-party
          content providers, or licensors make any guarantees that the use of
          the Services will be uninterrupted or error-free. We also make no
          warranties regarding the results that may be obtained from the use of
          the Services or the accuracy, reliability, or content of any provided
          information. <br />
          Any software, products, or other materials that can be downloaded,
          without limitation, are provided on an "as-is" basis without any
          warranties, either express or implied, including, but not limited to,
          any implied warranties of merchantability, fitness for a particular
          purpose, title, non-infringement, or any warranty arising out of a
          course of dealing, performance, or trade usage, except for warranties
          that cannot be disclaimed, limited, or changed under applicable laws.
          ANNA does not guarantee that your use of the Services will be
          uninterrupted or error-free, that ANNA will review the information or
          materials available through the Services for accuracy, or that it will
          preserve or maintain any such information or materials without loss.
          ANNA shall not be liable for delays, disruptions, service failures, or
          other issues related to the use of the Internet and electronic
          communications or other systems beyond ANNA's control. The foregoing
          disclaimers apply to the maximum extent permitted by law. You may have
          other legal rights, but the duration of any legally required
          warranties, if any, shall be limited to the maximum extent permitted
          by law.
          <br />
          Despite the information and materials provided through the Services
          being considered reliable, we make no representations, either directly
          or indirectly, regarding the accuracy, completeness, timeliness, or
          reliability of the Services. <br />
          To the extent permitted by law, we, our employees, subsidiaries,
          parent companies, agents, partners, third-party content providers,
          affiliates, suppliers, and/or our or their respective directors and
          officers shall not be liable for any injuries, losses, claims, or
          direct damages, or any special, exemplary, punitive, incidental, or
          indirect damages of any kind, whether based in contract, tort, or
          otherwise, including, but not limited to, loss of profit, bodily
          injury, or death, property damage, reputation damage, or loss of
          information or data, even if the possibility of such damages has been
          notified, arising from (1) this Agreement, (2) any use of the
          Services, in any way connected with or related to it, or (3) any
          failure or delay (including, but not limited to, the use or inability
          to use any component of booking services or payment services), or (4)
          your visit to any small or medium-sized business or productivity,
          non-performance, conduct, or policy of any small or medium-sized
          business or Customer in connection with the Services. Additionally,
          you clearly understand and agree that any third party recommending you
          to the ANNA website through referral, link, or any other means shall
          not be responsible to the user for any reason, including, but not
          limited to, damages or losses related to the use of the Services. ANNA
          is neither an agent nor otherwise affiliated with any small and
          medium-sized business for which a Customer has made a reservation or
          paid a bill using Payment Services.
          <br />
          We disclaim any liability of any kind for any unauthorized access or
          use of your personal information. By using the Services, you
          acknowledge and agree to our disclaimer of any such liability. If you
          do not agree, you should not access or otherwise use the Services.
          Some jurisdictions do not allow the limitation or exclusion of
          liability for incidental or consequential damages, so some of the
          above limitations may not apply to certain Users.
          <br />
          The above limitations remain in force after these Terms and are for
          the benefit of both us and our affiliates, as well as their respective
          directors, officers, employees, and agents. You and ANNA understand
          and agree that the disclaimers, exclusions, and limitations in this
          Section 9.1 are essential elements of this Agreement and that they
          represent a reasonable allocation of risks. In particular, you
          understand that ANNA would not be able to provide you with the
          Services except on these terms, and you agree that this Agreement will
          remain in effect and be enforceable even if any limited remedy
          specified in this Agreement does not reach its essential purpose.
        </p>
        <p className="termsSubtitle">9.2. Indemnification.</p>
        <p className="termsText">
          You agree to defend, indemnify, and hold us, as well as our
          affiliates, and respective directors, officers, employees, and agents
          harmless from and against all claims, lawsuits, and expenses,
          including attorney's fees, arising out of or related to your (a) use
          of the ANNA website and/or ANNA mobile application; (b) breach or
          violation of this agreement by you; (c) use of third-party services,
          platforms, products, links, advertisements, and/or tools by you; (d)
          your violation of any third-party rights, including intellectual
          property rights of third parties in submissions and feedback; (e)
          unauthorized use of the Services by any other person using your
          information; (f) your non-compliance with network rules or pci-dss
          security standards, including compromising any payment information.
        </p>
        <p className="termsSubtitle">9.3. Release of Liability.</p>
        <p className="termsText mb-3">
          You understand that small and medium-sized businesses and customers
          are solely responsible for their interactions with each other and any
          claims, injuries, illnesses, damages, liabilities, and expenses
          ("Claims") incurred by you or your small and medium-sized business
          representative as a result of your (or a similar recipient's)
          interaction with a User or visit to any SMB or any product or service
          of an SMB. Hereby, you release ANNA from any such Claims. In
          connection with the foregoing, if you are a resident of California,
          you hereby waive Section 1542 of the California Civil Code, which
          states: "A general release does not extend to claims which the
          creditor does not know or suspect to exist in his or her favor at the
          time of executing the release, which, if known by him or her must have
          materially affected his or her settlement with the debtor." You
          expressly waive and relinquish all rights and benefits under this
          section and any law of any jurisdiction of similar effect concerning
          release of unknown or unsuspected claims that you may have against
          ANNA relating to the subject of this Section 9.3.
        </p>
        <p className="termsSubtitle">
          Part X – Applicable Law; Arbitration Agreement and Waiver of Jury
          Trial, Class Action Waiver, and Forum Selection.
        </p>
        <p className="termsSubtitle">10.1. Governing Law.</p>
        <p className="termsText">
          This Agreement is entered into, governed by, and construed in
          accordance with the laws of the state of Delaware for those Users
          entering into this Agreement with ANNA, without giving effect to any
          principles that may require the application of the laws of another
          jurisdiction.
        </p>
        <p className="termsSubtitle">10.2. Arbitration.</p>
        <p className="termsText mb-3">
          Any disputes, controversies, claims, issues, demands, or causes of
          action (including the interpretation and scope of this clause, and the
          arbitrability of the disputes, controversies, claims, issues, demands,
          or causes of action) between you and ANNA or its successors or assigns
          shall be settled exclusively by mandatory and confidential
          arbitration.
          <br />
          The arbitration shall be governed by the UNCITRAL Arbitration Rules,
          not any state arbitration laws. The arbitration shall be conducted by
          a single commercial arbitrator with significant experience in
          resolving disputes arising from commercial contracts under UNCITRAL.
          Subject to modifications made by this Agreement, and unless otherwise
          agreed by the parties in writing, the arbitration will be governed by
          the UNCITRAL Commercial Arbitration Rules and, if applicable, the
          Supplementary Procedures for Consumer-Related Disputes (together, the
          "Rules and Procedures"). Therefore, you waive your right to go to
          court to assert or defend your rights, except for matters that you
          file in small claims court. Your rights will be determined by a
          neutral arbitrator, not a judge or jury. You are entitled to a fair
          hearing, but arbitration procedures are simpler and more limited than
          rules applicable in court. The arbitrator's decisions are as
          enforceable as any court order and are subject to very limited review
          by a court. You and ANNA must abide by the following rules: (1) any
          claims brought by you or ANNA must be brought in the party's
          individual capacity and not as a plaintiff or class member in any
          purported class or representative proceeding; (2) the arbitrator may
          not consolidate more than one person's claims, may not otherwise
          preside over any form of a representative or class proceeding, and may
          not award class-wide relief; (3) in the event that you are able to
          demonstrate that the costs of arbitration will be prohibitive as
          compared to the costs of court, ANNA will pay as much of your filing
          and hearing fees in connection with the arbitration as the arbitrator
          deems necessary to prevent the arbitration from being
          cost-prohibitive, (4) ANNA also reserves the right in its sole and
          exclusive discretion to assume responsibility for all of the costs of
          the arbitration; (5) the arbitrator must honor the requirements of
          privileges and privacy recognized by law; (6) the arbitration must be
          confidential, and neither you nor we may disclose the existence,
          content, or results of any arbitration, except as may be required by
          law or for purposes of enforcement of the arbitration award; (7) the
          arbitrator may award any individual relief or individual remedies that
          are permitted by applicable law; and (8) each party pays their own
          attorneys' fees and expenses unless there is a statutory provision
          that requires the prevailing party to be paid its fees and litigation
          expenses, and in such instance, the fees and costs awarded shall be
          determined by the applicable law.
          <br />
          Notwithstanding the foregoing, you or ANNA may bring an individual
          action in small claims court. Additionally, claims pertaining to
          defamation, violation of the Computer Fraud and Abuse Act, as well as
          infringement or misappropriation of another party's patent, copyright,
          trademark, or trade secret are not subject to this arbitration
          agreement. Such lawsuits shall be filed exclusively in the state or
          federal courts located in New York, New York. Furthermore, despite
          this arbitration agreement, either party may seek relief in the state
          or federal courts located in New York to maintain the status quo
          pending arbitration and hereby agrees to submit to the exclusive
          personal jurisdiction of the courts located in New York, New York for
          such purpose. The request for provisional remedies shall not be deemed
          a waiver of the right to arbitration.
          <br />
          Except for subparts (1) and (2) of this Section (which prohibit
          arbitration on a class or collective basis), if any part of this
          arbitration provision is deemed to be invalid, void, illegal, or
          otherwise unenforceable under the Rules and Procedures, then the
          remainder of this arbitration provision shall remain in effect and be
          interpreted in accordance with its terms as if the invalid, void,
          illegal, or unenforceable part were not contained herein. However, if
          either subpart (1) or (2) is found to be invalid, void, or illegal,
          then the entirety of this arbitration provision shall be null and
          void, and neither you nor ANNA shall be entitled to arbitration. If
          for any reason a claim proceeds in court rather than in arbitration,
          the dispute shall be exclusively brought in state or federal court
          located in New York, New York.
        </p>
        <p className="termsSubtitle">Section XI – Miscellaneous.</p>
        <p className="termsSubtitle">11.1. Customer Service.</p>
        <p className="termsText">
          If you have any questions, comments, or concerns regarding the
          Services, you can contact customer support at any time by calling
          +1(302)303-4616 or by email at office@incomm.space.
        </p>
        <p className="termsSubtitle">
          11.2. Disclosure of Affiliated Information.
        </p>
        <p className="termsText">
          We may have affiliated relationships with third parties and affiliated
          entities whose products and/or services we associate with and promote
          through the Services. Through this association, we may receive
          commissions for products purchased by the User from third-party
          affiliates or partners.
        </p>
        <p className="termsSubtitle">11.3. Authority.</p>
        <p className="termsText">
          Each Party represents and warrants to the other that it has all the
          authority and powers to enter into this Agreement, and that it is
          binding upon such Party and enforceable in accordance with its terms.
        </p>
        <p className="termsSubtitle">11.4. Waiver.</p>
        <p className="termsText">
          Any waiver of any rights under these Terms becomes effective only if
          agreed upon or declared in writing. Delay in exercising a right or
          failure to exercise a right does not constitute a waiver and does not
          prevent a Party from exercising that right in the future. The rights
          and remedies provided herein are cumulative and do not exclude any
          rights and remedies provided by law.
        </p>
        <p className="termsSubtitle">11.5. Force Majeure.</p>
        <p className="termsText">
          We shall not be obligated to fulfill any commitments if prevented from
          doing so by force majeure circumstances, including, but not limited
          to, actions taken or imposed by any government or governmental
          authority, or in the event of any other circumstances beyond our
          control, including, but not limited to, natural disasters (such as
          storms, hurricanes, fires, floods, earthquakes), war, civil unrest,
          terrorist activities, state of emergency, government sanctions,
          embargoes, nationalization, strikes, and failures in public services
          (such as electricity or telecommunication services). We will make all
          reasonable efforts to inform you of the circumstances causing the
          delay and to resume performance, both as soon as possible, without
          undue delay.
        </p>
        <p className="termsSubtitle">11.6. Assignment.</p>
        <p className="termsText">
          This Agreement and the rights and obligations granted hereunder may
          not be assigned, transferred, or delegated by the User in any manner
          but may be freely assigned, transferred, or delegated by ANNA.
        </p>
        <p className="termsSubtitle">11.7. Rights of Third Parties.</p>
        <p className="termsText">
          These Terms do not confer any rights on any third party unless
          expressly stated otherwise herein.
        </p>
        <p className="termsSubtitle">11.8. Relationship of the Parties.</p>
        <p className="termsText">
          Under these Terms, the parties are independent contractors, and
          nothing herein should be construed as creating a partnership, joint
          venture, or agency relationship between them. Neither party has the
          authority to make any representations on behalf of the other party or
          any third party who may have rights under these Terms.
        </p>
        <p className="termsSubtitle">11.9. Severability.</p>
        <p className="termsText">
          If any part of this Agreement is found to be invalid or unenforceable
          under applicable law, such invalid or unenforceable provision shall be
          replaced by a valid and enforceable provision that most closely
          matches the intent of the original provision, and the rest of this
          Agreement shall continue to be in force.
        </p>
        <p className="termsSubtitle">11.10. Notices.</p>
        <p className="termsText">
          Unless otherwise expressly indicated, any notices are sent by email to
          office@incomm.space, and in the case of any User, to the email address
          you provide to us during registration or when updating your email
          address. Notice is deemed given twenty-four (24) hours after sending
          an email unless the sending party receives notice that the email
          address is invalid. Additionally, we may send you notices by certified
          mail with prepaid postage and a request for acknowledgment to the
          address provided during Account registration. In such a case, notice
          is deemed given three (3) calendar days after the date of mailing.
        </p>
        <p className="termsSubtitle">11.11. Effective Date.</p>
        <p className="termsText">
          From time to time, we may update these Terms of Use by posting update
          notices on the website, sending notifications to registered users of
          our ANNA program, and/or contacting you via the email address you
          provided during registration. Therefore, we advise you to check them
          frequently.
          <br />
          <br />
          Last updated: January 01, 2024.
        </p>
      </div>
    </div>
  );
};

export default Terms;
