import React from "react";

import dash_UA from "./../../../assets/img/dash_UA.png";
import dash_EN from "./../../../assets/img/dash_EN.png";
import calendar_UA from "./../../../assets/img/calendar_UA.png";
import calendar_EN from "./../../../assets/img/calendar_EN.png";
import web_EN from "./../../../assets/img/web_EN.png";
import web_UA from "./../../../assets/img/web_UA.png";
import InfoBox from "./InfoBox";

const InfoBoxContainer = ({ textContent, selectLanguage }) => {
  return (
    <div className="px-2 w-full">
      <InfoBox
        titleText={textContent["onlineBookingTitle"][selectLanguage]}
        imgSrc={selectLanguage === "ua" ? dash_UA : dash_EN}
        descriptionText={
          textContent["onlineBookingDescription"][selectLanguage]
        }
      />
      <InfoBox
        titleText={textContent["digitalNotebookTitle"][selectLanguage]}
        imgSrc={selectLanguage === "ua" ? calendar_UA : calendar_EN}
        descriptionText={
          textContent["digitalNotebookDescription"][selectLanguage]
        }
      />
      <InfoBox
        titleText={textContent["webBookingTitle"][selectLanguage]}
        imgSrc={selectLanguage === "ua" ? web_EN : web_UA}
        descriptionText={textContent["webBookingDescription"][selectLanguage]}
      />
    </div>
  );
};

export default InfoBoxContainer;
