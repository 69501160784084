import React from "react";

const InfoBoxDescription = ({ imgSrc, descriptionText }) => {
  return (
    <div className="w-full flex justify-between items-center pt-3">
      <div className="h-[330px] max-w-2/4">
        <img className="h-full" src={imgSrc} />
      </div>

      <p className="w-2/4 px-2 text-base text-primaryText">{descriptionText}</p>
    </div>
  );
};

export default InfoBoxDescription;
