import React, { useEffect } from "react";
import { useState } from "react";
import data from "./../../app/data/data.js";

// import { useNavigate } from "react-router-dom";
import MobileScreen from "./MobileScreen";

const MainPage = () => {
  let language = "uk-UK";

  if (window.navigator.language != "uk-UA") {
    if (window.navigator.language != "ru-RU") {
      language = "en";
    } else {
      language = "ua";
    }
  } else language = "ua";

  const [selectLanguage, setSelectLanguage] = useState(language);

  const handleToggleLanguage = (text) => {
    setSelectLanguage(text);
  };
  // const navigate = useNavigate();

  //для прокрутки на початок сторінки
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="bg-background w-full relative flex flex-col items-center">
      <MobileScreen
        textContent={data}
        selectLanguage={selectLanguage}
        onToggleLanguage={handleToggleLanguage}
      />
    </div>
  );
};

export default MainPage;
