import React from "react";
import TextBox from "./TextBox";

const TextBoxContainer = ({ textContent, selectLanguage }) => {
  return (
    <div className="px-2 w-full">
      <TextBox
        title={textContent["crmTitle"][selectLanguage]}
        description={textContent["crmDescription"][selectLanguage]}
      />

      <TextBox
        title={textContent["remindersTitle"][selectLanguage]}
        description={textContent["remindersDescription"][selectLanguage]}
      />

      <TextBox
        title={textContent["controlTitle"][selectLanguage]}
        description={textContent["controlDescription"][selectLanguage]}
      />
    </div>
  );
};

export default TextBoxContainer;
