import React from "react";
import { useState } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import arrUp from "./../../../assets/ico/arrowUp.png";
import arrDown from "./../../../assets/ico/arrowDown.png";

const AccordionTextBox = ({ data, selectLanguage }) => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <div>
      {data.map((item, i) => (
        <div
          key={i}
          className="w-full p-2 mb-6 bg-itemBackground rounded-[20px] border border-[#4d2c6c] overflow-hidden"
        >
          <div className="w-full flex flex-col px-6" onClick={() => toggle(i)}>
            <div className="w-full flex justify-between">
              <p className="w-10/12 text-[20px] text-[#ffffff]">
                {item[selectLanguage]["question"]}
              </p>
              <span className=" pt-3">
                {selected === i ? <img src={arrUp} /> : <img src={arrDown} />}
              </span>
            </div>

            <div className="w-5/6 h-[4px] mt-2 bg-secondaryText"></div>
            <p
              className={
                selected === i ? "accordionContent show " : "accordionContent"
              }
            >
              {item[selectLanguage]["answer"]}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionTextBox;
