import React, {useEffect} from 'react';
import {Link, useRouteError} from "react-router-dom";


export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
    //для прокрутки на початок сторінки
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return (
        <div id="error-page"
             className='errorPage'>
            <h1 className='bigTitle mb-16'>Ой, щось пішло не так..!</h1>

            <Link
                to="/">
                <div className='button'>Повернутись на головну сторінку</div>
            </Link>
        </div>
    );
}
