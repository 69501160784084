import React from "react";
import android from "./../../../assets/ico/android-ico.svg";
import ios from "./../../../assets/ico/ios-ico.svg";

const DownloadBtn = () => {
  const handleSelectedAndroid = () => {
    console.log("selected android");

    window.location.assign(
      "https://play.google.com/store/apps/details?id=com.anna&hl=uk"
    );
  };
  const handleSelectedIos = () => {
    console.log("selected ios");
    window.location.assign(
      "https://apps.apple.com/ua/app/anna/id6450268086?l=uk"
    );
  };
  return (
    <div className="w-full">
      <div className="flex flex-row items-center px-[5%] mt-3 w-full justify-around">
        <button
          className="glow-on-hover"
          type="button"
          onClick={handleSelectedIos}
        >
          <div className="py-1 px-2 flex flex-nowrap justify-center items-center">
            <img src={ios} />
            <p className="text-primaryText fontSize-[22px] whitespace-nowrap font-thin leading-[28px] w-[90px]">
              App Store
            </p>
          </div>
        </button>

        <button
          className="glow-on-hover"
          type="button"
          onClick={handleSelectedAndroid}
        >
          <div className="py-1 px-2 flex flex-nowrap justify-center items-center">
            <img src={android} />
            <p className="text-primaryText fontSize-[22px] whitespace-nowrap font-thin leading-[28px] w-[90px]">
              Google Play
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default DownloadBtn;
